/**
 * Module dependencies.
 */

import { Locale, RegionCode } from 'i18n-routes';

/**
 * Constants.
 */

const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE as Locale;
const locales = process.env.NEXT_PUBLIC_LOCALES;
const regionCodes = process.env.NEXT_PUBLIC_REGION_CODES;

/**
 * Export `i18n` config.
 */

export const i18n = {
  defaultLocale,
  locales: locales.split(',') as Locale[],
  regionCodes: regionCodes
    .split(',')
    .map(code => [code.split('-')[0], code])
    .reduce(
      (acc, [locale, code]) => ({ ...acc, [locale]: code }),
      {}
    ) as Record<Locale, RegionCode>
};
