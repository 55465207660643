/**
 * Module dependencies.
 */

import { useEffect, useRef, useState } from 'react';

/**
 * Export `useIsHovered` hook.
 */

export function useIsHovered({ active }: { active: boolean }) {
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef<HTMLElement | null>(null);
  const handleMouseOver = () => setIsHovered(true);
  const handleMouseOut = () => setIsHovered(false);

  useEffect(() => {
    const item = ref.current;

    if (item && active) {
      item.addEventListener('mouseover', handleMouseOver);
      item.addEventListener('mouseout', handleMouseOut);

      return () => {
        item.removeEventListener('mouseover', handleMouseOver);
        item.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [active]);

  return { isHovered, ref };
}
