/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Control, useForm } from 'react-hook-form';
import { Drawer, DrawerProps } from 'src/components/core/drawer';
import { InputField } from 'src/components/core/forms/input/field';
import { RecaptchaField } from 'src/components/core/forms/recaptcha';
import { SelectField } from 'src/components/core/forms/select/field';
import { TermsField } from 'src/components/core/forms/terms/field';
import { apiEndpoints } from 'src/core/constants/api-endpoints';
import { axiosInstance } from 'src/core/utils/requests';
import { getDataFromTranslations } from 'src/core/utils/objects';
import { regexes } from 'src/core/constants/regexes';
import { toast } from 'src/providers/toast';
import { useCallback, useMemo } from 'react';
import { useEmailTemplateId } from 'src/hooks/use-email-template-id';
import { useTranslation } from 'next-i18next';

/**
 * Constants.
 */

const emailTemplateId = '33041916, 34448136, 35360282';

/**
 * `Props`  type.
 */

type Props = Omit<DrawerProps, 'children'>;

/**
 * `FormData` type.
 */

type FormData = {
  email: string;
  message: string;
  name: string;
  phone?: string;
  subject: string;
};

/**
 * Export `ContactFormRequest` component.
 */

export const ContactFormRequest = ({ onRequestClose, ...props }: Props) => {
  const { t } = useTranslation();
  const { control, formState, handleSubmit, reset } = useForm<FormData>();
  const isSubmitting = formState.isSubmitting;
  const { data } = useMemo(() => getDataFromTranslations<string>('common:forms.selectOptions', t), [t]);

  const selectOptions = data.map(item => ({
    label: item,
    value: item
  }));

  const templateId = useEmailTemplateId(emailTemplateId);

  const handleOnSubmit = useCallback(
    (formData: FormData) => {
      const { email, message, name, phone, subject } = formData;

      return axiosInstance
        .post(apiEndpoints.sendEmail, {
          email,
          templateId,
          templateModel: {
            body: `Name: ${name}<br>Email: ${email}<br>Phone: ${
              phone || '-'
            }<br>Subject: ${subject}<br>Message: ${message}`,
            requestType: 'contact'
          }
        })
        .then(() => {
          reset();
          onRequestClose();
          toast.success(t('common:forms.messages.success'));
        })
        .catch(() => {
          toast.success(t('common:forms.messages.error'));
        });
    },
    [onRequestClose, reset, t, templateId]
  );

  return (
    <Drawer {...props} onRequestClose={onRequestClose}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <InputField
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.name')}
          name={'name'}
          placeholder={t('common:forms.placeholders.name')}
          rules={{
            required: t('common:forms.rules.required')
          }}
        />

        <InputField
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.email')}
          name={'email'}
          placeholder={t('common:forms.placeholders.email')}
          rules={{
            pattern: {
              message: t('common:forms.rules.email'),
              value: regexes.email
            },
            required: t('common:forms.rules.required')
          }}
          type={'email'}
        />

        <InputField
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.phone')}
          name={'phone'}
          placeholder={t('common:forms.placeholders.phone')}
          rules={{
            pattern: {
              message: t('common:forms.rules.phone'),
              value: regexes.phone
            },
            required: t('common:forms.rules.required')
          }}
        />

        <SelectField
          control={control}
          disabled={isSubmitting}
          id={'subject'}
          label={t('common:forms.labels.subject')}
          name={'subject'}
          options={selectOptions}
          placeholder={t('common:forms.placeholders.subject')}
          rules={{
            required: t('common:forms.rules.required')
          }}
        />

        <InputField
          as={'textarea'}
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.message')}
          name={'message'}
          placeholder={t('common:forms.placeholders.message')}
          rows={3}
          rules={{
            required: t('common:forms.rules.required')
          }}
        />

        <div>
          <TermsField control={control as any} disabled={isSubmitting} />
        </div>

        <RecaptchaField action={'contact'} control={control as Control<any>} />

        <Button colorTheme={'primary'} disabled={isSubmitting} isLoading={isSubmitting} stretch type={'submit'}>
          {t('common:actions.sendMessage')}
        </Button>
      </form>
    </Drawer>
  );
};
