/**
 * Module dependencies.
 */

import { anchors } from 'src/core/constants/menu';
import { regexes } from 'src/core/constants/regexes';
import i18nRoutes, { Locale, RouteName } from 'i18n-routes';
import kebabCase from 'lodash/kebabCase';
import template from 'lodash/template';

/**
 * Export `getAnchor` util.
 */

export function getAnchor(route: RouteName, index: number) {
  return kebabCase(anchors[route][index]);
}

/**
 * Export `routeResolve` util.
 */

export function routeResolve(
  route: RouteName | undefined,
  params: (Record<string, string> & { locale: Locale }) | null
) {
  if (!route || !params?.locale) {
    throw new Error('Missing required "route/locale" parameters');
  }

  const locale = params.locale;

  if (!i18nRoutes[locale]?.[route as RouteName]) {
    throw new Error(`Missing "${locale}/${route}" route`);
  }

  return template(i18nRoutes[locale][route as RouteName], {
    interpolate: regexes.interpolateUrl
  })(params);
}
