/**
 * Module dependencies.
 */

import { CreateClientConfig, enableAutoPreviews } from '@prismicio/next';
import { createClient } from '@prismicio/client';

/**
 * Export `prismicRepoName`.
 */

export const prismicRepoName = process.env.NEXT_PUBLIC_PRISMIC_REPOSITORY_NAME;

/**
 * Export `createPrimsicClient`.
 */

export function createPrimsicClient({
  previewData,
  req,
  ...config
}: CreateClientConfig = {}) {
  const client = createClient(prismicRepoName, {
    routes: [
      {
        path: '/:lang?/blog/:uid',
        type: 'blog'
      }
    ],
    ...config
  });

  enableAutoPreviews({ client, previewData, req });

  return client;
}