/**
 * Module dependencies.
 */

import { HamburgerMenu } from './hamburger-menu';
import { Locale } from 'i18n-routes';
import { MenuItemProps, menu } from 'src/core/constants/menu';
import { MobileMenu } from './mobile-menu';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from '@untile/react-core/components/svg';
import { ifProp, switchProp } from 'styled-tools';
import { media } from '@untile/react-core/styles/media';
import { routeResolve } from 'src/core/utils/routes';
import { textStyles } from 'src/components/core/text';
import { transparentize } from 'src/styles/utils/colors';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCallback, useEffect, useState } from 'react';
import { useHreflangs } from 'src/hooks/use-hreflangs';
import { useIsHovered } from 'src/hooks/use-is-hovered';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Transition, { TransitionStatus } from 'react-transition-group/Transition';
import caretSvg from 'src/assets/svgs/caret-small.svg';
import kebabCase from 'lodash/kebabCase';
import logoSvg from 'src/assets/svgs/logo.svg';
import styled, { css } from 'styled-components';

/**
 * `Nav` styled component.
 */

const Nav = styled.nav<{
  isMenuMobileOpen: boolean;
  isUnderlayVisible: boolean;
}>`
  align-items: center;
  color: 'var(--color-text)';
  display: flex;
  gap: var(--space-xs);
  height: var(--navbar-height);
  justify-content: space-between;
  left: 0;
  padding: var(--gutter);
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--z-index-navbar);

  &[data-hover='true'] {
    color: var(--color-white);
  }

  ::before {
    background-color: ${transparentize('lightBrown300', 0.8)};
    content: '';
    inset: 0;
    opacity: 1;
    position: absolute;
    transform: translateY(-100%);
    transition: var(--transition-default);
    transition-property: opacity, transform;

    ${ifProp(
      'isUnderlayVisible',
      `
      transform: translateY(0);
      transition-delay: 0.3s;
    `
    )}

    ${ifProp(
      'isMenuMobileOpen',
      `
      opacity: 0;
      transition-delay: 0s;
    `
    )}
  }

  ${media.min.ms`
    padding: 36px var(--space-ms);
  `}
`;

/**
 * `Logo` styled component.
 */

const Logo = styled(RouterLink)`
  color: ${ifProp('colorTheme', 'var(--color-text)')};
  line-height: 0;
  outline: none;
  transition: color var(--transition-default);
`;

/**
 * `Link` styled component.
 */

const Link = styled(RouterLink)`
  ${textStyles.paragraph3}

  align-items: center;
  color: var(--color-darkBlue700);
  cursor: pointer;
  display: flex;
  font-weight: 700;
  gap: 4px;
  letter-spacing: 0.6px;
  outline: none;
  text-decoration: none;
  transition: color var(--transition-default);
  white-space: nowrap;

  &[data-active='true'] {
    color: var(--color-cian700);
  }

  &[data-hover='true'] {
    color: var(--color-white);
  }

  > span {
    top: -2px;
  }

  :focus,
  :focus-within,
  :hover {
    color: var(--color-cian700);
  }
`;

/**
 * `Button` styled component.
 */

const Button = styled(Link)`
  background-color: transparent;
  border: 1px solid var(--color-darkBlue700);
  border-radius: 8px;
  color: var(--color-darkBlue700);
  height: 40px;
  padding: 9px 16px 7px;
  transition: var(--transition-default);
  transition-property: background-color, border, color;

  &[data-active='true'] {
    background-color: var(--color-cian700);
    border: 1px solid var(--color-cian700);
    color: var(--color-white);
  }

  &[data-hover='true'] {
    border: 1px solid var(--color-white);
    color: var(--color-white);
  }

  &[data-hover='true'][data-active='true'] {
    color: var(--color-darkBlue700);
  }

  :focus,
  :focus-within,
  :hover {
    background-color: var(--color-cian700);
    border: 1px solid var(--color-cian700);
    color: var(--color-darkBlue700);

    &[data-hover='true'] {
      color: var(--color-white);
    }
  }
`;

/**
 * `MenuList` styled component.
 */

const MenuList = styled.ul`
  align-items: center;
  display: flex;
  gap: var(--space-xs);
`;

/**
 * `Dropdown` styled component.
 */

const Dropdown = styled.ul<{ status: TransitionStatus }>`
  background-color: var(--color-secondary);
  color: var(--color-text);
  left: -16px;
  padding: 16px 20px;
  position: absolute;
  top: var(--space-xs);
  transition: 0.3s ease-in-out;
  transition-property: opacity, transform;

  li {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  ${Link} {
    padding: 8px 0;
  }

  ${switchProp('status', {
    entered: css`
      opacity: 1;
      transform: translateY(0);
    `,
    entering: css`
      opacity: 0;
      transform: translateY(20px);
    `,
    exited: css`
      opacity: 0;
      transform: translateY(20px);
    `,
    exiting: css`
      opacity: 1;
      transform: translateY(0);
    `
  })}
`;

/**
 * `isLinkActice` function.
 */

const isLinkActice = (path: string, resolved: string) => {
  const basePath = path.split('#')[0];

  return basePath === `${resolved}/`;
};

/**
 * `MenuItem` component.
 */

const MenuItem = (props: MenuItemProps) => {
  const { anchors, isButton, isHoverVideo, routeName } = props;
  const { t } = useTranslation();
  const router = useRouter();
  const resolved = routeResolve(routeName, { locale: router.locale as Locale });
  const { isHovered, ref } = useIsHovered({ active: !!anchors });
  const LinkComponent = isButton ? Button : Link;

  return (
    <li
      {...(!!anchors && {
        'aria-expanded': isHovered,
        'aria-haspopup': true,
        tabIndex: isHovered ? 0 : -1
      })}
      ref={ref as any}
      role={'menuitem'}
      style={{ position: 'relative' }}
    >
      <LinkComponent
        aria-label={t(`common:navbar.menu.${routeName}`)}
        data-active={isLinkActice(router.asPath, resolved)}
        data-hover={isHoverVideo}
        href={resolved}
        style={{
          textTransform: 'uppercase'
        }}
        title={t(`common:navbar.menu.${routeName}`)}
      >
        {t(`common:navbar.menu.${routeName}`)}

        {!!anchors && <Svg icon={caretSvg} size={'16px'} />}
      </LinkComponent>

      <Transition
        in={isHovered && !!anchors}
        mountOnEnter
        nodeRef={ref}
        timeout={{
          enter: 100,
          exit: 100
        }}
        unmountOnExit
      >
        {(status: TransitionStatus) => (
          <Dropdown role={'menu'} status={status}>
            {anchors?.map(anchor => (
              <li key={anchor} role={'menuitem'}>
                <Link
                  aria-label={t(`common:navbar.menu.${anchor}`)}
                  href={`${routeResolve(routeName, {
                    locale: router.locale as Locale
                  })}#${kebabCase(anchor)}`}
                  title={t(`common:navbar.menu.${anchor}`)}
                >
                  {t(`common:navbar.menu.${anchor}`)}
                </Link>
              </li>
            ))}
          </Dropdown>
        )}
      </Transition>
    </li>
  );
};

/**
 * `LanguageSelector` component.
 */

const LanguageSelector = ({ isHoverVideo }: { isHoverVideo?: boolean }) => {
  const { isHovered, ref } = useIsHovered({ active: true });
  const hreflangs = useHreflangs();
  const router = useRouter();

  return (
    <li
      aria-expanded={isHovered}
      aria-haspopup
      ref={ref as any}
      role={'menuitem'}
      style={{ position: 'relative' }}
      tabIndex={isHovered ? 0 : -1}
    >
      <Link as={'span'} data-hover={isHoverVideo} style={{ textTransform: 'uppercase' }}>
        {router?.locale}

        <Svg icon={caretSvg} size={'16px'} />
      </Link>

      <Transition
        in={isHovered}
        mountOnEnter
        nodeRef={ref}
        timeout={{
          enter: 100,
          exit: 100
        }}
        unmountOnExit
      >
        {(status: TransitionStatus) => (
          <Dropdown role={'menu'} status={status}>
            {hreflangs.map(
              ({ locale, route }) =>
                locale !== router?.locale && (
                  <li key={locale} role={'menuitem'}>
                    <Link
                      aria-label={locale}
                      href={route}
                      locale={locale}
                      style={{ textTransform: 'uppercase' }}
                      title={locale}
                    >
                      {locale}
                    </Link>
                  </li>
                )
            )}
          </Dropdown>
        )}
      </Transition>
    </li>
  );
};

/**
 * Export `Navbar` component.
 */

export const Navbar = () => {
  const router = useRouter();
  const isMobile = useBreakpoint(0, 'md');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isUnderlayVisible, setIsUnderlayVisible] = useState(false);
  const [isHoverVideo, setIsHoverVideo] = useState(false);

  const handleCloseMenu = useCallback(() => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (!isMobile && isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }, [isMobile, isMobileMenuOpen]);

  useEffect(() => {
    if (router.asPath === '/') {
      setIsHoverVideo(true);
      setIsUnderlayVisible(false);
    } else {
      setIsHoverVideo(false);
    }

    const handleScroll = () => {
      if (router.asPath !== '/') {
        setIsUnderlayVisible(window.scrollY > 50);
      }

      if (router.asPath === '/') {
        const footer = document.getElementsByTagName('footer')[0];

        setIsHoverVideo(window.scrollY < footer?.offsetTop - 50);
        setIsUnderlayVisible(window.scrollY > footer?.offsetTop - 50);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [router.asPath]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [isMobileMenuOpen, router?.events]);

  return (
    <>
      <Nav
        aria-label={'Navigation'}
        data-hover={isHoverVideo}
        isMenuMobileOpen={isMobileMenuOpen}
        isUnderlayVisible={isUnderlayVisible}
      >
        <Logo
          aria-label={'Got to home page'}
          href={routeResolve('home', { locale: router.locale as Locale })}
          {...(isMobileMenuOpen && {
            colorTheme: 'var(--color-text)'
          })}
        >
          <Svg icon={logoSvg} size={'190px'} />
        </Logo>

        {isMobile ? (
          <HamburgerMenu isOpen={isMobileMenuOpen} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
        ) : (
          <MenuList role={'menu'}>
            {menu.map(item => (
              <MenuItem isHoverVideo={isHoverVideo} {...item} key={item?.routeName} />
            ))}

            <LanguageSelector isHoverVideo={isHoverVideo} />
          </MenuList>
        )}
      </Nav>

      {isMobile && <MobileMenu isOpen={isMobileMenuOpen} onCloseMenu={handleCloseMenu} />}
    </>
  );
};
