/**
 * Module dependencies.
 */

import { UseControllerProps, useController, useFormState } from 'react-hook-form';

import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

/**
 * `RecaptchaProps` type.
 */

type RecaptchaProps = {
  action: string;
  onToken: (token: string) => void;
};

/**
 * `RecaptchaFieldProps` type.
 */

type RecaptchaFieldProps = Omit<UseControllerProps, 'name'> & Omit<RecaptchaProps, 'onToken'>;

/**
 * Export `RecaptchaField` component.
 */

export function RecaptchaField(props: RecaptchaFieldProps) {
  const { action, control, defaultValue = '', rules, shouldUnregister } = props;
  const { submitCount } = useFormState({ control });
  const {
    field: { onChange }
  } = useController({
    control,
    defaultValue,
    name: 'recaptchaToken',
    rules: { required: true, ...rules },
    shouldUnregister
  });

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    try {
      const token = await executeRecaptcha(action);

      onChange(token);
    } catch (error) {
      // Handle error
    }
  }, [action, executeRecaptcha, onChange]);

  useEffect(() => {
    if (!isNaN(submitCount)) {
      handleReCaptchaVerify();
    }
  }, [handleReCaptchaVerify, submitCount]);

  return null;
}
