/**
 * Module dependencies.
 */

import { CaseStudyFormRequest } from 'src/components/form-request/case-study';
import { ContactFormRequest } from 'src/components/form-request/contact';
import { Context, Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';
import { SpontaneousFormRequest } from 'src/components/form-request/spontaneous';
import { VacancyFormRequest } from 'src/components/form-request/vacancy';

/**
 * `FormType` type.
 */

type FormType = 'contact' | 'vacancy' | 'spontaneous' | 'caseStudy';

/**
 * `Data` type.
 */

type Data = Record<string, unknown> | null;

/**
 * `FormRequest` type.
 */

type FormRequest = {
  setData: Dispatch<SetStateAction<Data>>;
  setOpenDrawer: Dispatch<SetStateAction<FormType | null>>;
};

/**
 * Export `FormRequestContext`.
 */

export const FormRequestContext: Context<FormRequest> = createContext({} as FormRequest);

/**
 * Export `useFormRequest` hook.
 */

export function useFormRequest(): FormRequest {
  return useContext(FormRequestContext);
}

/**
 * Export `FormRequestProvider` component.
 */

export function FormRequestProvider({ children }: { children: ReactNode }) {
  const [openDrawer, setOpenDrawer] = useState<FormType | null>(null);
  const [data, setData] = useState<Data | null>(null);

  return (
    <FormRequestContext.Provider value={{ setData, setOpenDrawer }}>
      {children}

      <CaseStudyFormRequest isOpen={openDrawer === 'caseStudy'} onRequestClose={() => setOpenDrawer(null)} />

      <ContactFormRequest isOpen={openDrawer === 'contact'} onRequestClose={() => setOpenDrawer(null)} />

      <SpontaneousFormRequest isOpen={openDrawer === 'spontaneous'} onRequestClose={() => setOpenDrawer(null)} />

      <VacancyFormRequest
        isOpen={openDrawer === 'vacancy'}
        onRequestClose={() => {
          setOpenDrawer(null);
          setData(null);
        }}
        vacancy={data?.vacancy as string}
      />
    </FormRequestContext.Provider>
  );
}
