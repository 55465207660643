/**
 * Module dependencies.
 */

import { switchProp } from 'styled-tools';
import { transparentize } from 'src/styles/utils/colors';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useEffect, useRef, useState } from 'react';
import Transition, { TransitionStatus } from 'react-transition-group/Transition';
import styled, { keyframes } from 'styled-components';

/**
 * `dotTrail` keyframe.
 */

const dotTrail = keyframes`
  10% {
    transform: translateZ(0) scale(.5);
  }

  90% {
    transform: translate3d(0, 72px, 0) scale(1);
  }

  to {
    transform: translate3d(0, 72px, 0) scale(1);
  }
`;

/**
 * `lineTrail` keyframe.
 */

const lineTrail = keyframes`
  10% {
    transform: scaleY(1);
  }

  90% {
    transform: scaleY(0);
  }

  to {
    transform: scaleY(0);
  }
`;

/**
 * `lineTrailAlt` keyframe.
 */

const lineTrailAlt = keyframes`
  10% {
    transform: scaleY(0);
  }

  90% {
    transform: scaleY(1);
  }

  to {
    transform: scaleY(1);
  }
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span<{ status: TransitionStatus }>`
  --animation-duration: 1.7s;
  --animation-play-state: running;
  --animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  --height: 88px;

  bottom: var(--space-xs);
  height: var(--height);
  position: fixed;
  right: 56px;
  transition: opacity 0.3s ease-in-out;
  width: 2.1rem;

  ::before,
  ::after {
    animation-direction: alternate;
    animation-duration: var(--animation-duration);
    animation-iteration-count: infinite;
    animation-play-state: var(--animation-play-state);
    animation-timing-function: var(--animation-timing-function);
    background: ${transparentize('darkBlue700', 0.2)};
    content: '';
    height: var(--height);
    left: 1rem;
    opacity: 0.2;
    position: absolute;
    width: 0.1rem;
  }

  ::before {
    animation-name: ${lineTrailAlt};
    top: 0;
    transform: scaleY(0);
    transform-origin: 50% 0;
  }

  ::after {
    animation-name: ${lineTrail};
    bottom: 0;
    transform-origin: 50% 100%;
  }

  ${switchProp('status', {
    entered: `opacity: 1;`,
    entering: `opacity: 0;`,
    exited: `opacity: 0;`,
    exiting: `opacity: 1;`
  })}
`;

/**
 * `VisuallyHidden` styled component.
 */

const VisuallyHidden = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px !important;
  margin: -1px;
  overflow: hidden !important;
  padding: 0;
  position: absolute !important;
  width: 1px !important;
`;

/**
 * `Dot` styled component.
 */

const Dot = styled.span`
  animation: ${dotTrail} var(--animation-duration) var(--animation-timing-function) infinite alternate;
  animation-play-state: var(--animation-play-state);
  background: var(--color-text);
  border-radius: 50%;
  height: 0.9rem;
  left: 0.6rem;
  position: absolute;
  top: 0;
  transform: translateZ(0) scale(0.5);
  width: 0.9rem;
  z-index: 1;
`;

/**
 * Export `ScrollIndicator` component.
 */

export const ScrollIndicator = () => {
  const ref = useRef<HTMLElement | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const isMobile = useBreakpoint(0, 'ms');

  useEffect(() => {
    const handleScroll = () => {
      if (isMobile) {
        return;
      }

      setIsVisible(window.scrollY < 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  return (
    <Transition in={isVisible && !isMobile} mountOnEnter nodeRef={ref} timeout={300} unmountOnExit>
      {(status: TransitionStatus) => (
        <Wrapper ref={ref} status={status}>
          <VisuallyHidden />

          <Dot />
        </Wrapper>
      )}
    </Transition>
  );
};
