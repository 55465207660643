/**
 * Module dependencies.
 */

import { ReactNode, useRef } from 'react';
import { Svg } from '@untile/react-core/components/svg';
import { switchProp } from 'styled-tools';
import { transparentize } from 'src/styles/utils/colors';
import { useBodyScroll } from '@untile/react-core/hooks/use-body-scroll';
import Transition, { TransitionStatus } from 'react-transition-group/Transition';
import closeSvg from 'src/assets/svgs/close.svg';
import styled, { css } from 'styled-components';

/**
 * Export `DrawerProps` type.
 */

export type DrawerProps = {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  onRequestClose: () => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ status: TransitionStatus }>`
  background-color: var(--color-white);
  bottom: 0;
  max-width: 664px;
  padding: 96px var(--gutter) 56px;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: var(--z-index-drawer);

  ${switchProp('status', {
    entered: css`
      transform: translateX(0);
      transition: transform 300ms;
    `,
    entering: css`
      transform: translateX(100%);
    `,
    exiting: css`
      transform: translateX(100%);
      transition: transform 300ms;
    `
  })}
`;

/**
 * `Underlay` styled component.
 */

const Underlay = styled.div<{ status: TransitionStatus }>`
  background-color: ${transparentize('darkBlue700', 0.8)};
  inset: 0;
  opacity: 0;
  position: fixed;
  transition: opacity var(--transition-default);
  z-index: 1;

  ${switchProp('status', {
    entered: css`
      opacity: 1;
    `,
    entering: css`
      opacity: 0;
    `,
    exiting: css`
      opacity: 0;
      transition-delay: 100ms;
    `
  })}
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled.button`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background: none;
  border: none;
  color: var(--color-darkBlue700);
  outline: none;
  position: absolute;
  right: var(--gutter);
  top: var(--space-sm);
  transition: color var(--transition-default);

  :focus,
  :focus-within,
  :hover {
    color: var(--color-primary);
  }
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
`;

/**
 * Export `Drawer` component.
 */

export const Drawer = (props: DrawerProps) => {
  const { children, className, isOpen, onRequestClose } = props;
  const ref = useRef(null);

  useBodyScroll({ off: !!isOpen });

  return (
    <Transition
      in={isOpen}
      mountOnEnter
      nodeRef={ref}
      timeout={{
        enter: 0,
        exit: 300
      }}
      unmountOnExit
    >
      {(status: TransitionStatus) => (
        <>
          <Underlay status={status} />

          <Wrapper className={className} ref={ref} status={status}>
            <CloseButton aria-label={'Close'} data-cursor={'none'} onClick={onRequestClose}>
              <Svg icon={closeSvg} size={'32px'} />
            </CloseButton>

            <ContentWrapper>
              <Content>{children}</Content>
            </ContentWrapper>
          </Wrapper>
        </>
      )}
    </Transition>
  );
};
