/**
 * Module dependencies.
 */

import { Context, ReactNode, createContext, useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

/**
 * `Navigation` type.
 */

type Navigation = {
  isRefresh: boolean;
  isRouteChange: boolean;
};

/**
 * Export `NavigationContext`.
 */

export const NavigationContext: Context<Navigation> = createContext({} as Navigation);

/**
 * Export `useNavigation` hook.
 */

export function useNavigation(): Navigation {
  return useContext(NavigationContext);
}

/**
 * Export `NavigationProvider` component.
 */

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const [navigationState, setNavigationState] = useState({
    isRefresh: true,
    isRouteChange: false
  });

  const previousPath = useRef<string | null>(null);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setNavigationState({
        isRefresh: previousPath.current === url,
        isRouteChange: previousPath.current !== url
      });

      previousPath.current = url;
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, router.asPath]);

  return <NavigationContext.Provider value={navigationState}>{children}</NavigationContext.Provider>;
};
