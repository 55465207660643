/**
 * Module dependencies.
 */

import { ifProp } from 'styled-tools';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  isOpen: boolean;
  onClick?: () => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span<Props & { isOpen: boolean }>`
  appearance: none;
  background: none;
  border: none;
  color: ${ifProp('isOpen', 'var(--color-text)')};
  cursor: pointer;
  height: var(--space-sm);
  padding: 0;
  position: relative;
  width: var(--space-sm);
`;

/**
 * `Icon` styled component.
 */

const Icon = styled.span<Pick<Props, 'isOpen'>>`
  height: 0;
  position: absolute;
  top: 14px;
  transition: var(--transition-default);
  width: 22px;

  ::before,
  ::after {
    background-color: currentcolor;
    border-radius: 2px;
    content: '';
    height: 2px;
    left: 5px;
    position: absolute;
    transition: var(--transition-default);
    transition-property: background-color, transform;
    width: 22px;
  }

  ::before {
    top: -6px;
  }

  ::after {
    top: 7px;
  }

  ${ifProp(
    'isOpen',
    `
    color: var(--color-text);

    ::before {
      transform: rotateZ(45deg) translate(4px, 5px);
    }

    ::after {
      transform: rotateZ(-45deg) translate(4px, -5px);
    }
  `
  )}
`;

/**
 * `Point` styled component.
 */

const Point = styled.span<Pick<Props, 'isOpen'>>`
  background-color: currentcolor;
  border-radius: 3px;
  height: 3px;
  left: 50%;
  opacity: ${ifProp('isOpen', '0', '1')};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity var(--transition-default);
  width: 3px;
`;

/**
 * Export `HamburgerMenu` component.
 */

export const HamburgerMenu = (props: Props) => {
  const { isOpen } = props;

  return (
    <Wrapper {...props} aria-label={'Toggle menu'} data-cursor={'none'} isOpen={isOpen} role={'button'}>
      <Icon isOpen={isOpen} />

      <Point isOpen={isOpen} />
    </Wrapper>
  );
};
