/**
 * Export `colors`.
 */

export const colors = {
  black: '#000000',
  blue500: '#87969C',
  cian600: '#9be5f0',
  cian700: '#6bc9d7',
  darkBlue200: '#d0dada',
  darkBlue600: '#535e5d',
  darkBlue700: '#051b19',
  green500: '#60a065',
  green600: '#397d40',
  grey100: '#fcfcfc',
  grey400: '#dbdbdb',
  grey500: '#c9c9c9',
  grey600: '#afafaf',
  grey700: '#6d6d6d',
  grey800: '#282828',
  lightBrown300: '#f4f4ef',
  lightBrown400: '#d1ccc2',
  red500: '#d64c56',
  red600: '#a60814',
  white: '#ffffff'
};
