/**
 * Module dependencies.
 */

import { ChangeEvent, FormEventHandler, forwardRef } from 'react';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { Input, InputProps } from './index';
import { assignRefs } from 'src/core/utils/refs';

/**
 * Export `InputFieldProps` interface.
 */

export type InputFieldProps = Omit<InputProps, 'id' | 'isRequired'> & {
  control: Control<any>;
  defaultValue?: any;
  name: string;
  onChangeNormalizer?: (
    e: ChangeEvent<HTMLInputElement>,
    onChange: FormEventHandler<HTMLInputElement> & ((event: ChangeEvent<HTMLInputElement>) => void),
    value: InputProps['value']
  ) => void;
  rules?: RegisterOptions;
};

/**
 * Export `InputField` component.
 */

export const InputField = forwardRef((props: InputFieldProps, forwardedRef: any) => {
  const { control, defaultValue = '', name, onChangeNormalizer, rules, type, ...rest } = props;

  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error }
  } = useController({
    control,
    defaultValue,
    name,
    rules
  });

  return (
    <Input
      {...rest}
      error={error?.message}
      hasError={!!error}
      id={name}
      isRequired={!!rules?.required}
      onBlur={onBlur}
      onChange={
        !onChangeNormalizer
          ? onChange
          : event => onChangeNormalizer(event as ChangeEvent<HTMLInputElement>, onChange, value)
      }
      ref={assignRefs(forwardedRef, ref)}
      type={type ?? 'text'}
      value={value}
    />
  );
});

/**
 * `InputField` display name.
 */

InputField.displayName = 'InputField';
