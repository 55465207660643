/**
 * Module dependencies.
 */

import { CheckboxField } from 'src/components/core/forms/checkbox/field';
import { Control } from 'react-hook-form';
import { Trans, useTranslation } from 'next-i18next';
import { assetUrlResolve } from 'src/core/utils/url';
import { useRouter } from 'next/router';

/**
 * `Props` type.
 */

type Props = {
  control: Control<any>;
  disabled?: boolean;
};

/**
 * Export `TermsField` component.
 */

export const TermsField = (props: Props) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  return (
    <CheckboxField
      {...props}
      label={
        <Trans
          components={[
            <a
              href={assetUrlResolve(`/files/${locale}/privacy-policy.pdf`)}
              key={'terms-link'}
              rel={'noopener noreferrer'}
              style={{ textDecoration: 'underline' }}
              target={'_blank'}
            />
          ]}
          i18nKey={'common:forms.labels.terms'}
          transSupportBasicHtmlNodes
        />
      }
      name={'terms'}
      rules={{
        required: t('common:forms.rules.terms')
      }}
    />
  );
};
