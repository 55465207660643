/**
 * Module dependencies.
 */

import { ErrorMessage, FormControl, FormGroup, Label } from 'src/components/core/forms/styles';
import { Ref, forwardRef } from 'react';
import styled from 'styled-components';

/**
 * Export `InputProps` interface.
 */

export type InputProps = React.HTMLProps<HTMLInputElement> & {
  as?: 'input' | 'textarea';
  className?: string;
  disabled: boolean;
  error?: string;
  hasError?: boolean;
  id: string;
  isRequired?: boolean;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ref?: Ref<HTMLInputElement>;
};

/**
 * `StyledInput` styled component.
 */

const StyledInput = styled(FormControl)<Omit<InputProps, 'error' | 'isRequired' | 'label'>>``;

/**
 * Export `Input` component.
 */

export const Input = forwardRef((props: InputProps, ref: any) => {
  const { className, error, hasError, id, isRequired, label, type, ...rest } = props;

  return (
    <FormGroup className={className} data-cursor={'none'}>
      <Label htmlFor={id}>
        {label}

        {isRequired && <sup>{'*'}</sup>}
      </Label>

      <StyledInput hasError={hasError} id={id} ref={ref} type={type ?? 'text'} {...rest} />

      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </FormGroup>
  );
});

/**
 * `Input` display name.
 */

Input.displayName = 'Input';
