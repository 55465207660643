/**
 * Module dependencies.
 */

import { ErrorMessage, FormControl, FormGroup, Label } from 'src/components/core/forms/styles';
import { Ref, forwardRef } from 'react';
import { Svg } from '@untile/react-core/components/svg';
import styled from 'styled-components';
import uploadSvg from 'src/assets/svgs/upload.svg';

/**
 * Export `InputFileProps` interface.
 */

export type InputFileProps = React.HTMLProps<HTMLInputElement> & {
  as?: 'input' | 'textarea';
  className?: string;
  disabled: boolean;
  error?: string;
  hasError?: boolean;
  id: string;
  isRequired?: boolean;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ref?: Ref<HTMLInputElement>;
  value?: File;
};

/**
 * `StyledInput` styled component.
 */

const StyledInput = styled(FormControl).attrs({
  as: 'div'
})<Omit<InputFileProps, 'error' | 'isRequired' | 'label'>>`
  input {
    inset: 0;
    opacity: 0;
    position: absolute;
  }
` as any;

/**
 * `UploadIcon` styled component.
 */

const UploadIcon = styled(Svg).attrs({
  icon: uploadSvg,
  size: '24px'
})`
  color: var(--color-secondary);
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

/**
 * `Placeholder` styled component.
 */

const Placeholder = styled.span`
  color: var(--color-grey600);
`;

/**
 * `Value` styled component.
 */

const Value = styled.span`
  color: var(--color-grey700);
`;

/**
 * Export `InputFile` component.
 */

export const InputFile = forwardRef((props: InputFileProps, ref: any) => {
  const { className, error, hasError, id, isRequired, label, placeholder, value, ...rest } = props;

  return (
    <FormGroup className={className} data-cursor={'none'}>
      <Label htmlFor={id}>
        {label}

        {isRequired && <sup>{'*'}</sup>}
      </Label>

      <div style={{ position: 'relative' }}>
        <StyledInput hasError={hasError}>
          <input id={id} ref={ref} {...rest} type={'file'} />

          {placeholder && !value && <Placeholder>{placeholder}</Placeholder>}

          {!!value && <Value>{value?.name}</Value>}

          <UploadIcon />
        </StyledInput>
      </div>

      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </FormGroup>
  );
});

/**
 * `InputFile` display name.
 */

InputFile.displayName = 'InputFile';
