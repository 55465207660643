/**
 * Module dependencies.
 */

import { CookiesBar } from './cookies-bar';
import { Footer } from './footer';
import { Navbar } from './navbar';
import { PageTransition } from './page-transition';
import { ReactNode } from 'react';
import { ScrollIndicator } from './scroll-indicator';
import dynamic from 'next/dynamic';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  hideFooter?: boolean;
};

/**
 *  `Cursor` component.
 */

const Cursor = dynamic(() => import('../core/cursor').then(mod => mod.Cursor), {
  ssr: false
});

/**
 * Export `Layout` component.
 */

export const Layout = ({ children, hideFooter }: Props) => (
  <PageTransition>
    <Navbar />

    <Cursor />

    {children}

    {!hideFooter && <Footer />}

    <ScrollIndicator />

    <CookiesBar />
  </PageTransition>
);
