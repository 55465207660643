/**
 * Module dependencies.
 */

import { FontProperties, setFontStyle } from 'src/styles/utils/typography';
import { ReactNode } from 'react';
import { ifProp, switchProp } from 'styled-tools';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

/**
 * `createFontStyle` util.
 */

const createFontStyle = (properties: FontProperties) => {
  if (isEmpty(properties) || !properties) {
    throw new Error(`🚨 No font properties provided.`);
  }

  return css`
    ${setFontStyle(properties)}

    margin: 0;
  `;
};

/**
 * `display1` styles.
 */

const display1 = {
  fontSize: 112,
  fontSizeMin: 64,
  fontWeight: 700,
  lineHeight: 120,
  lineHeightMin: 72
} as FontProperties;

/**
 * `display2` styles.
 */

const display2 = {
  fontSize: 104,
  fontSizeMin: 64,
  fontWeight: 700,
  lineHeight: 104,
  lineHeightMin: 64
} as FontProperties;

/**
 * `display3` styles.
 */

const display3 = {
  fontSize: 88,
  fontSizeMin: 40,
  fontWeight: 700,
  lineHeight: 88,
  lineHeightMin: 48
} as FontProperties;

/**
 * `h1` styles.
 */

const h1 = {
  fontSize: 72,
  fontSizeMin: 64,
  fontWeight: 700,
  lineHeight: 80,
  lineHeightMin: 72
} as FontProperties;

/**
 * `h2` styles.
 */

const h2 = {
  fontSize: 64,
  fontSizeMin: 40,
  fontWeight: 700,
  lineHeight: 72,
  lineHeightMin: 48
} as FontProperties;

/**
 * `h3` styles.
 */

const h3 = {
  fontSize: 56,
  fontSizeMin: 32,
  fontWeight: 700,
  lineHeight: 64,
  lineHeightMin: 40
} as FontProperties;

/**
 * `h4` styles.
 */

const h4 = {
  fontSize: 40,
  fontSizeMin: 24,
  fontWeight: 700,
  lineHeight: 48,
  lineHeightMin: 32
} as FontProperties;

/**
 * `h5` styles.
 */

const h5 = {
  fontSize: 32,
  fontSizeMin: 18,
  fontWeight: 700,
  lineHeight: 40,
  lineHeightMin: 24
} as FontProperties;

/**
 * `h6` styles.
 */

const h6 = {
  fontSize: 24,
  fontWeight: 700,
  lineHeight: 32
} as FontProperties;

/**
 * `paragraph1` styles.
 */

const paragraph1 = {
  fontSize: 24,
  fontSizeMin: 18,
  fontWeight: 300,
  lineHeight: 40,
  lineHeightMin: 32
} as FontProperties;

/**
 * `paragraph2` styles.
 */

const paragraph2 = {
  fontSize: 18,
  fontWeight: 300,
  lineHeight: 32
} as FontProperties;

/**
 * `paragraph3` styles.
 */

const paragraph3 = {
  fontSize: 14,
  fontWeight: 300,
  lineHeight: 24
} as FontProperties;

/**
 * Variants themes.
 */

export const textStyles = {
  display1: createFontStyle(display1),
  display2: createFontStyle(display2),
  display3: createFontStyle(display3),
  h1: createFontStyle(h1),
  h2: createFontStyle(h2),
  h3: createFontStyle(h3),
  h4: createFontStyle(h4),
  h5: createFontStyle(h5),
  h6: createFontStyle(h6),
  paragraph1: createFontStyle(paragraph1),
  paragraph2: createFontStyle(paragraph2),
  paragraph3: createFontStyle(paragraph3)
} as const;

/**
 * `Props` type.
 */

type Props = {
  as?:
    | 'a'
    | 'address'
    | 'blockquote'
    | 'div'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'small'
    | 'strong'
    | 'sup'
    | 'i';
  block?: boolean;
  children: ReactNode;
  italic?: boolean;
  variant?: keyof typeof textStyles;
  weight?: 300 | 400 | 600 | 700;
};

/**
 * Export `Text` styled component.
 */

export const Text = styled.span<Props>`
  ${switchProp('variant', textStyles, textStyles.paragraph1)}
  ${ifProp('block', 'display: block;')}
  ${ifProp('italic', 'font-style: italic;')}
  ${({ weight }) => weight && `font-weight: ${weight};`}
`;
