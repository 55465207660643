/**
 * Module dependencies.
 */

import { ReactNode } from 'react';
import AnimateHeight, { AnimateHeightProps } from 'react-animate-height';

/**
 * `Props` type.
 */

type Props = Omit<AnimateHeightProps, 'height'> & {
  children: ReactNode;
  className?: string;
  id?: string;
  isOpen: boolean;
};

/**
 * Export `Collapse` component.
 */

export const Collapse = (props: Props) => {
  const { children, isOpen, ...rest } = props;

  return (
    <AnimateHeight duration={500} height={isOpen ? 'auto' : 0} {...rest}>
      {children}
    </AnimateHeight>
  );
};
