/**
 * Module dependencies.
 */

import { TFunction } from 'i18next';

/**
 * Export `getKeyByValueFromObject` util.
 */

export function getKeyByValueFromObject(object: Record<string, any>, value: any) {
  const entry = Object.entries(object).find(([, val]) => val === value);

  return entry && entry[0];
}

/**
 * Export `getDataFromTranslations` util.
 */

export function getDataFromTranslations<TData>(path: string, t: TFunction<'translation', undefined>) {
  try {
    const items = t(path, { returnObjects: true }) as Record<string, TData>;
    const data = Object.keys(items).map(key => items[key]);

    return {
      data,
      total: data?.length
    };
  } catch {
    return {
      data: [],
      total: 0
    };
  }
}
