/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Control, useForm } from 'react-hook-form';
import { Drawer, DrawerProps } from 'src/components/core/drawer';
import { InputField } from 'src/components/core/forms/input/field';
import { InputFileField } from 'src/components/core/forms/input-file/field';
import { RecaptchaField } from 'src/components/core/forms/recaptcha';
import { TermsField } from 'src/components/core/forms/terms/field';
import { apiEndpoints } from 'src/core/constants/api-endpoints';
import { axiosInstance } from 'src/core/utils/requests';
import { regexes } from 'src/core/constants/regexes';
import { toast } from 'src/providers/toast';
import { useCallback } from 'react';
import { useEmailTemplateId } from 'src/hooks/use-email-template-id';
import { useTranslation } from 'next-i18next';

/**
 * Constants.
 */

const emailTemplateId = '33041917, 34448540, 35360154';

/**
 * `Props`  type.
 */

type Props = Omit<DrawerProps, 'children'>;

/**
 * `FormData` type.
 */

type FormData = {
  cvFile: File;
  email: string;
  linkedin?: string;
  message?: string;
  name: string;
  phone: string;
  urls?: string;
};

/**
 * Export `SpontaneousFormRequest` component.
 */

export const SpontaneousFormRequest = ({ onRequestClose, ...props }: Props) => {
  const { t } = useTranslation();
  const { control, formState, handleSubmit, reset } = useForm<FormData>();
  const isSubmitting = formState.isSubmitting;
  const templateId = useEmailTemplateId(emailTemplateId);

  const handleOnSubmit = useCallback(
    (formData: FormData) => {
      const { cvFile, email, linkedin, message, name, phone, urls } = formData;
      const data = new FormData();

      data.append('attachment', cvFile as File);
      data.append('email', email);
      data.append('templateId', String(templateId));
      data.append(
        'templateModel',
        JSON.stringify({
          body: `Name: ${name}<br>Email: ${email}<br>Phone: ${phone}<br>Linkedin: ${linkedin || '-'}<br>Urls: ${
            urls || '-'
          }<br>Message: ${message || '-'}`,
          requestType: 'spontaneous'
        })
      );

      return axiosInstance
        .post(apiEndpoints.sendEmail, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(() => {
          reset();
          onRequestClose();
          toast.success(t('common:forms.messages.success'));
        })
        .catch(() => {
          toast.success(t('common:forms.messages.error'));
        });
    },
    [onRequestClose, reset, t, templateId]
  );

  return (
    <Drawer {...props} onRequestClose={onRequestClose}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <InputField
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.name')}
          name={'name'}
          placeholder={t('common:forms.placeholders.name')}
          rules={{
            required: t('common:forms.rules.required')
          }}
        />

        <InputField
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.email')}
          name={'email'}
          placeholder={t('common:forms.placeholders.email')}
          rules={{
            pattern: {
              message: t('common:forms.rules.email'),
              value: regexes.email
            },
            required: t('common:forms.rules.required')
          }}
          type={'email'}
        />

        <InputField
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.phone')}
          name={'phone'}
          placeholder={t('common:forms.placeholders.phone')}
          rules={{
            pattern: {
              message: t('common:forms.rules.phone'),
              value: regexes.phone
            },
            required: t('common:forms.rules.required')
          }}
        />

        <InputField
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.linkedin')}
          name={'linkedin'}
          placeholder={t('common:forms.placeholders.linkedin')}
        />

        <InputField
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.urls')}
          name={'urls'}
          placeholder={t('common:forms.placeholders.urls')}
        />

        <InputFileField
          accept={'.pdf,.doc,.docx'}
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.uploadCv')}
          name={'cvFile'}
          placeholder={t('common:forms.placeholders.uploadCv')}
          rules={{
            required: t('common:forms.rules.required'),
            validate: value => value?.size < 5242880 || t('common:forms.rules.uploadMaxSize', { size: 5 })
          }}
        />

        <InputField
          as={'textarea'}
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.message')}
          name={'message'}
          placeholder={t('common:forms.placeholders.message')}
          rows={5}
        />

        <div>
          <TermsField control={control as any} disabled={isSubmitting} />
        </div>

        <RecaptchaField action={'spontaneous'} control={control as Control<any>} />

        <Button colorTheme={'primary'} disabled={isSubmitting} isLoading={isSubmitting} stretch type={'submit'}>
          {t('common:actions.sendMessage')}
        </Button>
      </form>
    </Drawer>
  );
};
