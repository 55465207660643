/**
 * Module dependencies.
 */

import { Collapse } from 'src/components/core/collapse';
import { LegalMenuItem, MenuItemProps, getLegalMenu, menu } from 'src/core/constants/menu';
import { Locale } from 'i18n-routes';
import { RouterLink } from 'src/components/core/links/router-link';
import { Svg } from '@untile/react-core/components/svg';
import { routeResolve } from 'src/core/utils/routes';
import { switchProp } from 'styled-tools';
import { textStyles } from 'src/components/core/text';
import { useHreflangs } from 'src/hooks/use-hreflangs';
import { useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Transition, { TransitionStatus } from 'react-transition-group/Transition';
import caretSvg from 'src/assets/svgs/caret-small.svg';
import kebabCase from 'lodash/kebabCase';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  isOpen: boolean;
  onCloseMenu: () => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.aside<Props & { status: TransitionStatus }>`
  background-color: var(--color-secondary);
  bottom: 0;
  color: var(--color-text);
  display: grid;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr repeat(2, min-content);
  left: 0;
  overflow-y: auto;
  padding: calc(var(--navbar-height) + 20px) var(--gutter) var(--space-xs);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: var(--z-index-mobile-menu);

  ${switchProp('status', {
    entered: css`
      transform: translateX(0);
      transition: transform 300ms;
    `,
    entering: css`
      transform: translateX(-100%);
    `,
    exiting: css`
      transform: translateX(-100%);
      transition: transform 300ms;
    `
  })}
`;

/**
 * `LanguageWrapper` styled component.
 */

const LanguageWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: var(--space-xs);
`;

/**
 * `LanguageLink` styled component.
 */

const LanguageLink = styled(RouterLink)`
  ${textStyles.paragraph3}

  color: var(--color-text);
  font-weight: 700;
  text-transform: uppercase;
  transition: color var(--transition-default);

  :focus,
  :focus-within,
  :hover {
    color: var(--color-primary);
  }
`;

/**
 * `LegalWrapper` styled component.
 */

const LegalWrapper = styled.div`
  ${textStyles.paragraph3}

  align-items: center;
  color: var(--color-text);
  display: flex;
  gap: 8px;
`;

/**
 * `LegalLink` styled component.
 */

const LegalLink = styled.a`
  color: var(--color-text);

  :focus,
  :focus-within,
  :hover {
    color: var(--color-primary);
    text-decoration: underline;
  }
`;

/**
 * `MenuList` styled component.
 */

const MenuList = styled.ul`
  display: grid;
  grid-row-gap: var(--space-xs);
  grid-template-columns: 1fr;
`;

/**
 * `MenuLink` styled component.
 */

const MenuLink = styled(RouterLink)`
  align-items: center;
  color: var(--color-text);
  cursor: pointer;
  display: flex;
  font-family: var(--font-family-poly-sans);
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  outline: none;
  text-decoration: none;
  transition: color var(--transition-default);
  white-space: nowrap;
  width: min-content;

  > span {
    top: -2px;
  }

  :focus,
  :focus-within,
  :hover {
    color: var(--color-text);
  }
`;

/**
 * `ListAnchors` styled component.
 */

const ListAnchors = styled.ul`
  display: grid;
  grid-row-gap: 16px;
  grid-template-columns: 1fr;
  padding-left: 40px;
  padding-top: var(--space-xs);
`;

/**
 * `AnchorLink` styled component.
 */

const AnchorLink = styled(RouterLink)`
  align-items: center;
  color: var(--color-darkBlue600);
  cursor: pointer;
  display: flex;
  font-family: var(--font-family-poly-sans);
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  outline: none;
  text-decoration: none;
  transition: color var(--transition-default);
  white-space: nowrap;
  width: min-content;

  :focus,
  :focus-within,
  :hover {
    color: var(--color-text);
  }
`;

/**
 * `MenuItem` component.
 */

const MenuItem = (props: MenuItemProps & Pick<Props, 'onCloseMenu'>) => {
  const { anchors, onCloseMenu, routeName } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const isTouchDevice = useMemo(() => {
    return 'ontouchstart' in window || window.TouchEvent || navigator.maxTouchPoints > 0;
  }, []);

  return (
    <li
      onMouseOut={() => setIsOpen(false)}
      onMouseOver={() => setIsOpen(true)}
      {...(isTouchDevice && {
        onTouchEnd: () => setIsOpen(!isOpen)
      })}
      role={'menuitem'}
    >
      <MenuLink
        {...(isTouchDevice && !!anchors
          ? {
              as: 'span'
            }
          : {
              href: routeResolve(routeName, { locale: router.locale as Locale })
            })}
        style={{ textTransform: 'uppercase' }}
        title={t(`common:navbar.menu.${routeName}`)}
      >
        {t(`common:navbar.menu.${routeName}`)}

        {!!anchors && <Svg icon={caretSvg} size={'16px'} />}
      </MenuLink>

      {!!anchors && (
        <Collapse isOpen={isOpen}>
          <ListAnchors>
            {anchors?.map(anchor => (
              <li key={anchor} role={'menuitem'}>
                <AnchorLink
                  aria-label={t(`common:navbar.menu.${anchor}`)}
                  href={`${routeResolve(routeName, {
                    locale: router.locale as Locale
                  })}#${kebabCase(anchor)}`}
                  onClick={() => onCloseMenu()}
                  title={t(`common:navbar.menu.${anchor}`)}
                >
                  {t(`common:navbar.menu.${anchor}`)}
                </AnchorLink>
              </li>
            ))}
          </ListAnchors>
        </Collapse>
      )}
    </li>
  );
};

/**
 * `LanguageSelector` component.
 */

const LanguageSelector = () => {
  const hreflangs = useHreflangs();

  return (
    <LanguageWrapper>
      {hreflangs.map(({ locale, route }) => (
        <LanguageLink href={route} key={locale} locale={locale} title={locale}>
          {locale}
        </LanguageLink>
      ))}
    </LanguageWrapper>
  );
};

/**
 * Export `MobileMenu` component.
 */

export const MobileMenu = (props: Props) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const legalMenu = getLegalMenu(locale as Locale);
  const ref = useRef<HTMLElement | null>(null);

  return (
    <Transition
      in={props?.isOpen}
      mountOnEnter
      nodeRef={ref}
      timeout={{
        enter: 0,
        exit: 300
      }}
      unmountOnExit
    >
      {(status: TransitionStatus) => (
        <Wrapper {...props} ref={ref} status={status}>
          <div>
            <MenuList role={'menu'}>
              {menu?.map((item: MenuItemProps) => (
                <MenuItem {...item} key={item?.routeName} onCloseMenu={props?.onCloseMenu} />
              ))}
            </MenuList>
          </div>

          <LanguageSelector />

          <LegalWrapper>
            {legalMenu?.map(
              (item: LegalMenuItem) =>
                item.showInMobileMenu && (
                  <LegalLink
                    aria-label={t(`common:legal.${item.name}`)}
                    href={item.url}
                    key={item.name}
                    rel={'noopener noreferrer'}
                    target={'_blank'}
                  >
                    {t(`common:legal.${item.name}`)}
                  </LegalLink>
                )
            )}

            <span>{`Ⓒ HumanIT ${new Date().getFullYear()}`}</span>
          </LegalWrapper>
        </Wrapper>
      )}
    </Transition>
  );
};
