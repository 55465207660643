/**
 * Module dependencies.
 */

import { isMobile, isTablet } from 'react-device-detect';

/**
 * Export `useMouseDetect` hook.
 */

export const useMouseDetect = () => {
  if (isMobile || isTablet) {
    return false;
  }

  return true;
};
