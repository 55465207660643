/**
 * Module dependencies.
 */

import { ChangeEvent, FormEventHandler, forwardRef } from 'react';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import { Select, SelectProps } from './index';
import { assignRefs } from 'src/core/utils/refs';

/**
 * Export `SelectFieldProps` interface.
 */

export type SelectFieldProps = SelectProps & {
  control: Control<any>;
  defaultValue?: any;
  name: string;
  onChangeNormalizer?: (
    e: ChangeEvent<HTMLSelectElement>,
    onChange: FormEventHandler<HTMLSelectElement> & ((event: ChangeEvent<HTMLSelectElement>) => void),
    value: SelectProps['value']
  ) => void;
  rules?: RegisterOptions;
};

/**
 * Export `SelectField` component.
 */

export const SelectField = forwardRef((props: SelectFieldProps, forwardedRef: any) => {
  const { control, defaultValue = '', name, onChangeNormalizer, rules, ...rest } = props;

  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error }
  } = useController({
    control,
    defaultValue,
    name,
    rules
  });

  return (
    <Select
      {...rest}
      error={error?.message}
      hasError={!!error}
      id={name}
      isRequired={!!rules?.required}
      onBlur={onBlur}
      onChange={
        !onChangeNormalizer
          ? onChange
          : event => onChangeNormalizer(event as ChangeEvent<HTMLSelectElement>, onChange, value)
      }
      ref={assignRefs(forwardedRef, ref)}
      value={value}
    />
  );
});

/**
 * `SelectField` display name.
 */

SelectField.displayName = 'SelectField';
