/**
 * Module dependencies.
 */

import { createGlobalStyle } from 'styled-components';
import { media } from '@untile/react-core/styles/media';
import { rootVariables } from './variables';

/**
 * Export `GlobalStyle` component.
 */

export const GlobalStyle = createGlobalStyle`
  ${rootVariables}

  #__next {
    position: relative;
  }

  html {
    scroll-behavior: smooth;
    scroll-padding-top: 80px;
  }

  body {
    background-color: var(--color-secondary);
    color: var(--color-text);
    font-display: swap;
    font-family: var(--font-family-poly-sans);
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    overflow-x: hidden;
    scrollbar-color: var(--scrollbar-thumb-background) var(--scrollbar-background);
    scrollbar-width: thin;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  /* stylelint-disable */
  ::-moz-selection {
    background: var(--text-selection-background);
    color: var(--text-selection-color);
  }

  ::selection {
    background: var(--text-selection-background); 
    color: var(--text-selection-color);
  }

  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }

  ::-webkit-scrollbar-track {
    background: var(--scrollbar-background);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-background);
    border: calc(var(--scrollbar-thumb-width) / 2) solid var(--scrollbar-background);
    border-radius: var(--scrollbar-border-radius);
  }
  /* stylelint-enable */

  ${media.min.ms`
    html {
      scroll-padding-top: 100px;
    }
  `}
`;
