/**
 * Export `FontProperties` interface.
 */

export interface FontProperties {
  fontFamily?: string;
  fontSize: number;
  fontSizeMax?: number;
  fontSizeMin?: number;
  fontWeight?: number;
  italic?: boolean;
  letterSpacing?: number;
  lineHeight: number;
  lineHeightMax?: number;
  lineHeightMin?: number;
}

/**
 * Gets the VW size for the 1440px design breakpoint.
 */

const getVwSize = (size: number): number => Number(((size * 100) / 1440).toFixed(3));

/**
 * Check if value is truly and return result, otherwise returns empty string.
 */

const ifValue = (value: string | number | boolean | undefined, result: string): string => {
  return (value && result) || '';
};

/**
 * Export `setFontStyle` util.
 */

export const setFontStyle = (properties: FontProperties): string => {
  const {
    fontFamily,
    fontSize,
    fontSizeMax = fontSize,
    fontSizeMin = fontSize,
    fontWeight,
    italic,
    letterSpacing,
    lineHeight,
    lineHeightMax = lineHeight,
    lineHeightMin = lineHeight
  } = properties;

  const vwFontSize = getVwSize(fontSize);
  const isFluid = fontSize !== fontSizeMin || fontSize !== fontSizeMax;
  const maxBreakpoint = parseInt(((fontSizeMax / vwFontSize) * 100).toString(), 10);

  const minBreakpoint = parseInt(((fontSizeMin / vwFontSize) * 100).toString(), 10);

  return `
    ${ifValue(fontFamily, `font-family: ${fontFamily};`)}
    ${ifValue(letterSpacing, `letter-spacing: ${letterSpacing}px;`)}
    ${ifValue(fontWeight, `font-weight: ${fontWeight};`)}
    ${ifValue(italic, `font-style: italic;`)}

    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;

    ${ifValue(
      isFluid,
      `
      font-size: ${vwFontSize}vw;
      line-height: ${getVwSize(lineHeight)}vw;

      ${ifValue(
        fontSizeMin && minBreakpoint,
        `
        @media (max-width: ${minBreakpoint}px) {
          font-size: ${fontSizeMin}px;
          line-height: ${lineHeightMin ?? (fontSizeMin * lineHeight) / fontSize}px;
        }
      `
      )}

      ${ifValue(
        fontSizeMax && maxBreakpoint,
        `
        @media (min-width: ${maxBreakpoint}px) {
          font-size: ${fontSizeMax}px;
          line-height: ${lineHeightMax ?? (fontSizeMax * lineHeight) / fontSize}px;
        }
      `
      )}
    `
    )}
  `;
};
