/**
 * Module dependencies
 */

import { ButtonHTMLAttributes, DetailedHTMLProps, ElementType, forwardRef } from 'react';
import { Loading } from 'src/components/core/loading';
import { Svg } from '@untile/react-core/components/svg';
import { ifProp, switchProp } from 'styled-tools';
import { setButtonLinkProps } from 'src/core/utils/links';
import { textStyles } from 'src/components/core/text';
import styled from 'styled-components';

/**
 * Export `buttonThemesConfig` constant.
 */

export const buttonThemesConfig = {
  primary: {
    active: {
      backgroundColor: 'var(--color-cian700)',
      borderColor: 'var(--color-cian700)',
      textColor: 'var(--color-text)'
    },
    idle: {
      backgroundColor: 'var(--color-primary)',
      borderColor: 'var(--color-primary)',
      textColor: 'var(--color-text)'
    }
  },
  quaternary: {
    active: {
      backgroundColor: 'var(--color-cian700)',
      borderColor: 'var(--color-cian700)',
      textColor: 'var(--color-text)'
    },
    idle: {
      backgroundColor: 'transparent',
      borderColor: 'var(--color-primary)',
      textColor: 'var(--color-primary)'
    }
  },
  secondary: {
    active: {
      backgroundColor: 'var(--color-cian700)',
      borderColor: 'var(--color-cian700)',
      textColor: 'var(--color-text)'
    },
    idle: {
      backgroundColor: 'transparent',
      borderColor: 'var(--color-darkBlue700)',
      textColor: 'var(--color-darkBlue700)'
    }
  },
  terciary: {
    active: {
      backgroundColor: 'var(--color-white)',
      borderColor: 'var(--color-white)',
      textColor: 'var(--color-text)'
    },
    idle: {
      backgroundColor: 'transparent',
      borderColor: 'var(--color-white)',
      textColor: 'var(--color-white)'
    }
  }
};

/**
 * `buttonTheme´ constant.
 */

const buttonThemes = Object.entries(buttonThemesConfig).reduce(
  (previous, [themeName, config]) => ({
    ...previous,
    [themeName]: `
      --button-background-color: ${config.idle.backgroundColor};
      --button-border-color: ${config.idle.borderColor};
      --button-text-color: ${config.idle.textColor};
      --button-active-background-color: ${config.active.backgroundColor};
      --button-active-border-color: ${config.active.borderColor};
      --button-active-text-color: ${config.active.textColor};
    `
  }),
  {}
);

/**
 * Export `ButtonProps` type.
 */

export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  as?: ElementType;
  colorTheme?: 'dark' | 'primary' | 'secondary' | 'terciary' | 'quaternary';
  hideCursor?: boolean;
  href?: string;
  icon?: string;
  isLoading?: boolean;
  rel?: string;
  reverse?: boolean;
  stretch?: boolean;
  target?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button
  .withConfig({
    shouldForwardProp: prop => !['colorTheme', 'icon', 'isLoading', 'reverse', 'stretch'].includes(prop)
  })
  .attrs(setButtonLinkProps)<ButtonProps & { hasIcon: boolean }>`
  ${textStyles.paragraph2}
  ${switchProp('colorTheme', buttonThemes)}

  -webkit-tap-highlight-color: transparent;
  align-items: center;
  background-color: var(--button-background-color);
  border: 1px solid var(--button-border-color);
  border-radius: 8px;
  color: var(--button-text-color);
  cursor: pointer;
  display: inline-flex;
  font-weight: 700;
  height: 56px;
  justify-content: center;
  min-width: 160px;
  outline: none;
  padding: 12px 16px;
  position: relative;
  transition: var(--transition-default);
  transition-property: background-color, border-color, color, opacity;

  :focus,
  :focus-within,
  :hover {
    background-color: var(--button-active-background-color);
    border-color: var(--button-active-border-color);
    color: var(--button-active-text-color);
  }

  ${ifProp('stretch', 'width: 100%;')}

  ${ifProp(
    'disabled',
    `
    background-color: var(--color-grey400);
    border-color: var(--color-grey400);
    color: var(--color-grey600);
    cursor: default;
    pointer-events: none;
  `
  )}
`;

/**
 * `Content` styled component.
 */

const Content = styled.span<
  Pick<ButtonProps, 'isLoading' | 'reverse'> & {
    hasIcon: boolean;
  }
>`
  align-items: center;
  display: flex;
  flex-direction: ${ifProp('reverse', 'row-reverse', 'row')};
  grid-gap: ${ifProp('hasIcon', '8px', '0')};
  opacity: 1;
  transition: opacity var(--transition-default);

  ${ifProp(
    'isLoading',
    `
    opacity: 0;
    pointer-events: none;
  `
  )}
`;

/**
 * Export `Button` component.
 */

export const Button = forwardRef<any, ButtonProps>((props: ButtonProps, ref: any) => {
  const { children, colorTheme = 'primary', disabled, hideCursor, icon, isLoading, reverse, ...rest } = props;

  return (
    <Wrapper
      colorTheme={colorTheme}
      disabled={isLoading || disabled}
      isLoading={isLoading}
      ref={ref}
      {...rest}
      {...(!hideCursor && { 'data-cursor': 'zoom' })}
    >
      {isLoading && <Loading />}

      <Content hasIcon={!!icon} isLoading={isLoading} reverse={reverse}>
        {icon && <Svg icon={icon} size={'24px'} />}

        {children}
      </Content>
    </Wrapper>
  );
});

/**
 * `Button` display name.
 */

Button.displayName = 'Button';
