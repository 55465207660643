/**
 * Module dependencies.
 */

import { Circle } from './circle';
import { Ellipse } from './ellipse';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { ifProp, switchProp } from 'styled-tools';
import { media } from '@untile/react-core/styles/media';
import { textStyles } from 'src/components/core/text';
import { usePageTransition } from 'src/hooks/use-page-transition';
import { useTranslation } from 'next-i18next';
import keyframes from 'src/styles/keyframes';
import styled, { css } from 'styled-components';
/**
 * `Animation` type.
 */

type Animation = 'start' | 'end';

/**
 * `Tagline` styled component.
 */

const Tagline = styled.div<{ animation?: Animation }>`
  align-items: center;
  animation-duration: 750ms;
  animation-timing-function: ease-in-out;
  display: flex;
  inset: 0;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: var(--z-index-page-transition-tag);

  ${switchProp('animation', {
    end: css`
      animation-name: ${keyframes.fadeOut};
      pointer-events: none;
    `,
    start: css`
      animation-name: ${keyframes.fadeInDelayed};
      opacity: 1;
      pointer-events: all;
    `
  })}
`;

/**
 * `Title` styled component.
 */

const Title = styled.span`
  color: var(--color-darkBlue700);
  max-width: 80%;
  text-align: center;

  ${textStyles.h2}

  ${media.min.ms`
    ${textStyles.display1 as any}
  `}
`;

/**
 * `ChildrenWrapper` styled component.
 */

const ChildrenWrapper = styled.div<{ visible?: boolean }>`
  opacity: ${ifProp('visible', 1, 0)};
  transition: opacity 100ms ease-in-out;
`;

/**
 * `BackLayer` component.
 */

const BackLayer = ({ animation, transitionIndex }: { animation?: Animation; transitionIndex?: number }) => {
  switch (transitionIndex) {
    case 1:
      return <Circle animation={animation} direction={'growing'} />;

    case 2:
      return <Circle animation={animation} direction={'shrinking'} />;

    default:
      return <Ellipse animation={animation} />;
  }
};

/**
 * Export `PageTransition` component.
 */

export const PageTransition = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const taglines: string[] = useMemo(() => t('common:taglines', { returnObjects: true }), [t]);
  const [taglineIndex, setTaglineIndex] = useState<number>(0);
  const [transitionIndex, setTransitionIndex] = useState<number>();
  const [isMounted, setIsMounted] = useState(false);
  const handleEntry = useCallback(() => {
    setTransitionIndex(oldIndex => (oldIndex === undefined ? 0 : (oldIndex + 1) % 3));
    setTaglineIndex(oldIndex => (oldIndex + 1) % taglines.length);
  }, [taglines.length]);

  const animation = usePageTransition({
    hold: 2000,
    onEnter: handleEntry
  });

  useEffect(() => {
    if (!isMounted) {
      setTaglineIndex(0);
      setTransitionIndex(0);
      setIsMounted(true);
    }
  }, [isMounted]);

  return (
    <>
      <BackLayer animation={animation} transitionIndex={transitionIndex} />

      <Tagline animation={animation}>
        <Title>{taglines[taglineIndex ?? 0]}</Title>
      </Tagline>

      <ChildrenWrapper visible={animation === 'end'}>{children}</ChildrenWrapper>
    </>
  );
};
