/**
 * Export `getRotatedEllipseScale` util.
 */

export const getRotatedEllipseScale = ({ angle, vh, vw }: { angle: number; vh: number; vw: number }) => {
  const width = vh > vw ? -2 * vw : 2 * vw;
  const height = angle < 0 ? -2 * vh : 2 * vh;
  const rightQuadrantsAngle = Math.abs(angle) > Math.PI / 2 ? -angle : angle;

  return Math.sqrt(
    (width * Math.cos(rightQuadrantsAngle) - height * Math.sin(rightQuadrantsAngle)) ** 2 / (2 * width ** 2) +
      (width * Math.sin(rightQuadrantsAngle) + height * Math.cos(rightQuadrantsAngle)) ** 2 / (2 * height ** 2)
  );
};

/**
 * Export `getCircleScale` util.
 */

export const getCircleScale = ({ vh, vw }: { vh: number; vw: number }) => {
  return Math.sqrt(vh ** 2 + vw ** 2) / Math.min(vh, vw);
};
