/**
 * Module dependencies.
 */

import { Animation } from 'src/hooks/use-page-transition';
import { getCircleScale } from 'src/core/utils/geometry';
import { prop } from 'styled-tools';
import { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  animation?: Animation;
  direction: 'growing' | 'shrinking';
};

/**
 * Keyframe animations.
 */

const animations = {
  growing: {
    enter: keyframes`
      from {
        transform: translate(-100%, 100%) scale(0);
      }

      to {
        transform: translate(0, 0) scale(1);
      }
    `,
    exit: keyframes`
      from {
        transform: translate(0, 0) scale(1);
      }

      to {
        transform: translate(50%, -100%) scale(0.5);
      }
    `
  },
  shrinking: {
    enter: keyframes`
      from {
        transform: translate(50%, -100%) scale(0.5);
      }

      to {
        transform: translate(0, 0) scale(1);
      }
    `,
    exit: keyframes`
      from {
        transform: translate(0, 0) scale(1);
      }

      to {
        transform: translate(-100%, 100%) scale(0);
      }
    `
  }
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ scale?: number }>`
  height: 100vmin;
  left: 50vw;
  pointer-events: none;
  position: fixed;
  top: 50vh;
  transform: translate(-50%, -50%) scale(${prop('scale', 4)});
  width: 100vmin;
  z-index: var(--z-index-page-transition);
`;

/**
 * `Shape` styled component.
 */

const Shape = styled.div<Props>`
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  background-color: white;
  border-radius: 50%;
  inset: 0;
  position: fixed;

  ${({ animation, direction }) => {
    switch (animation) {
      case 'start':
        return css`
          animation-name: ${animations[direction].enter};
          pointer-events: all;
          transform: translate(0, 0) scale(1);
        `;

      case 'end':
        return css`
          animation-name: ${animations[direction].exit};
          pointer-events: none;
          transform: translate(-100%, 100%) scale(0);
        `;

      default:
        return '';
    }
  }}
`;

/**
 * Export `Circle` component.
 */

export const Circle = (props: Props) => {
  const [scale, setScale] = useState<number>();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setScale(
      getCircleScale({
        vh: window.innerHeight,
        vw: window.innerWidth
      })
    );
  }, []);

  return (
    <Wrapper scale={scale}>
      <Shape {...props} />
    </Wrapper>
  );
};
