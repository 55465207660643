/**
 * Module dependencies.
 */

import { Checkbox, CheckboxProps } from './index';
import { UseControllerProps, useController } from 'react-hook-form';
import { assignRefs } from 'src/core/utils/refs';
import { forwardRef } from 'react';

/**
 * `CheckboxFieldProps` type.
 */

type CheckboxFieldProps = CheckboxProps & Pick<UseControllerProps, 'control' | 'defaultValue' | 'name' | 'rules'>;

/**
 * Export `CheckboxField` component.
 */

export const CheckboxField = forwardRef((props: CheckboxFieldProps, forwardedRef: any) => {
  const { control, defaultValue = false, name, rules, ...rest } = props;
  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error }
  } = useController({
    control,
    defaultValue,
    name,
    rules
  });

  return (
    <Checkbox
      {...rest}
      error={error?.message}
      onBlur={onBlur}
      onChange={onChange}
      ref={assignRefs(forwardedRef, ref)}
      value={value}
    />
  );
});

/**
 * `CheckboxField` display name.
 */

CheckboxField.displayName = 'CheckboxField';
