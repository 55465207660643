/**
 * Export `Location` type.
 */

export type Location = {
  addressCountry: string;
  addressLocality: string;
  imageUrl: string;
  lat: number;
  lng: number;
  locationVideoUrl: string;
  name: string;
  postalCode: string;
  streetAddress: string;
  videoUrl: string;
};

/**
 * Export `LocationName` type.
 */

export type LocationName = 'covilha' | 'geneva' | 'lisbon' | 'oporto';

/**
 * Export `locations`.
 */

export const locations = {
  covilha: {
    addressCountry: 'contacts:countries.portugal',
    addressLocality: 'contacts:locations.covilha',
    imageUrl: '/images/contacts/covilha.webp',
    lat: 40.27900689513246,
    lng: -7.504106435006356,
    locationVideoUrl: '/videos/locations/covilha.mp4',
    name: 'contacts:locations.covilha',
    postalCode: '6200-013',
    streetAddress: 'Rua António Augusto Aguiar nº60'
  },
  geneva: {
    addressCountry: 'contacts:countries.switzerland',
    addressLocality: 'contacts:locations.geneva',
    imageUrl: '/images/contacts/geneva.webp',
    lat: 46.20512787472747,
    lng: 6.14357479803391,
    locationVideoUrl: '/videos/locations/geneva.mp4',
    name: 'contacts:locations.geneva',
    postalCode: '1204',
    streetAddress: "Quai de I'lle 13"
  },
  lisbon: {
    addressCountry: 'contacts:countries.portugal',
    addressLocality: 'contacts:locations.lisbon',
    imageUrl: '/images/contacts/lisbon.webp',
    lat: 38.73290348348124,
    lng: -9.142699373242952,
    locationVideoUrl: '/videos/locations/lisbon.mp4',
    name: 'contacts:locations.lisbon',
    postalCode: '1000-117',
    streetAddress: 'Avenida Defensores de Chaves nº4'
  },
  oporto: {
    addressCountry: 'contacts:countries.portugal',
    addressLocality: 'contacts:locations.oporto',
    imageUrl: '/images/contacts/oporto.webp',
    lat: 41.14430089633663,
    lng: -8.615550238306831,
    locationVideoUrl: '/videos/locations/oporto.mp4',
    name: 'contacts:locations.oporto',
    postalCode: '4050-542',
    streetAddress: 'Rua São Bento da Vitória nº 46 - Office 9'
  }
} as Record<LocationName, Location>;

/**
 * Export `orderedLocations`.
 */

export const orderedLocations: LocationName[] = ['lisbon', 'oporto', 'covilha', 'geneva'];
