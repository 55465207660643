/**
 * Module dependencies.
 */

import { Locale } from 'i18n-routes';
import { useRouter } from 'next/router';

/**
 * Constants.
 */

const publicLocales = process.env.NEXT_PUBLIC_LOCALES;

/**
 * Export `useEmailTemplateId` hook.
 */

export function useEmailTemplateId(templateId: string) {
  const router = useRouter();

  const templates = templateId.split(',');
  const locales = publicLocales.split(',') as Locale[];

  return router.locale === locales[0] ? parseInt(templates[0], 10) : parseInt(templates[1], 10);
}
