/**
 * Module dependencies.
 */

import { RouteName } from 'i18n-routes';
import { assetUrlResolve } from 'src/core/utils/url';

/**
 * Export `MenuItemProps`.
 */

export type MenuItemProps = {
  anchors?: Array<string>;
  isButton?: boolean;
  isHoverVideo?: boolean;
  routeName: RouteName;
};

/**
 * Export `anchors`.
 */

export const anchors = {
  aboutUs: ['weAre', 'ourCulture', 'ourHumans'],
  careers: ['workAt', 'perks', 'opportunities'],
  services: ['whatWeDo', 'technologies', 'expertise']
} as Record<RouteName, Array<string>>;

/**
 * Export `menu`.
 */

export const menu = [
  {
    anchors: anchors.aboutUs,
    routeName: 'aboutUs'
  },
  {
    anchors: anchors.services,
    routeName: 'services'
  },
  {
    anchors: anchors.careers,
    routeName: 'careers'
  },
  {
    routeName: 'blog'
  },
  {
    isButton: true,
    routeName: 'contacts'
  }
] as MenuItemProps[];

/**
 * Export `LegalMenuItem` type.
 */

export type LegalMenuItem = {
  name: string;
  showInMobileMenu: boolean;
  url: string;
};

/**
 * Export `getLegalMenu`.
 */

export const getLegalMenu = (locale: string): LegalMenuItem[] => [
  {
    name: 'privacyPolicy',
    showInMobileMenu: true,
    url: assetUrlResolve(`/files/${locale}/privacy-policy.pdf`)
  },
  {
    name: 'cookiesPolicy',
    showInMobileMenu: true,
    url: assetUrlResolve(`/files/${locale}/cookies-policy.pdf`)
  },
  {
    name: 'complaintBook',
    showInMobileMenu: false,
    url: process.env.NEXT_PUBLIC_COMPLAINT_BOOK_URL
  },
  {
    name: 'whistleblowerChannel',
    showInMobileMenu: false,
    url: process.env.NEXT_PUBLIC_WHISTLEBLOWER_CHANNEL_URL
  }
];
