/**
 * Module dependencies.
 */

import { ErrorMessage, FormGroup } from 'src/components/core/forms/styles';
import { ReactNode, forwardRef } from 'react';
import { Svg } from '@untile/react-core/components/svg';
import { Text } from 'src/components/core/text';
import { ifProp } from 'styled-tools';
import checkboxCheckedSvg from 'src/assets/svgs/checkbox-checked.svg';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Export `CheckboxProps` type.
 */

export type CheckboxProps = Omit<React.HTMLProps<HTMLInputElement>, 'type' | 'as' | 'label'> & {
  className?: string;
  disabled?: boolean;
  error?: string;
  label: ReactNode;
};

/**
 * `CheckmarkWrapper` styled component.
 */

const CheckmarkWrapper = styled.span`
  border: 1px solid var(--checkbox-outer-border-color);
  border-radius: 4px;
  grid-area: checkbox;
  height: var(--checkbox-size);
  position: relative;
  transition: var(--transition-fast);
  transition-property: background-color, border-color;
  width: var(--checkbox-size);

  > span {
    background-color: var(--checkbox-inner-background-color);
    border: 2px solid var(--checkbox-inner-border-color);
    border-radius: 3px;
  }
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg)`
  color: var(--checkbox-icon-color);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity var(--transition-fast);
`;

/**
 * `Label` styled component.
 */

const Label = styled.label<{
  disabled?: boolean;
  hasError?: boolean;
  hasLabel?: boolean;
}>`
  --checkbox-icon-color: var(--color-white);
  --checkbox-inner-background-color: var(--color-white);
  --checkbox-inner-border-color: var(--color-grey500);
  --checkbox-outer-border-color: transparent;
  --checkbox-size: 24px;

  align-items: flex-start;
  color: var(--checkbox-label-color);
  cursor: pointer;
  display: grid;
  grid-column-gap: 12px;
  grid-template-areas: 'checkbox label';
  grid-template-columns: var(--checkbox-size) 1fr;
  padding: 8px 0;
  position: relative;
  width: 100%;
  width: max-content;

  input:checked ~ ${CheckmarkWrapper} {
    --checkbox-inner-background-color: var(--color-darkBlue600);
    --checkbox-inner-border-color: var(--color-darkBlue600);
  }

  :hover {
    --checkbox-inner-border-color: var(--color-grey600);
  }

  :focus-within,
  :focus {
    --checkbox-inner-border-color: var(--color-grey600);
    --checkbox-outer-border-color: var(--color-text);
  }

  :focus-within,
  :focus,
  :hover {
    input:checked ~ ${CheckmarkWrapper} {
      --checkbox-inner-background-color: var(--color-text);
      --checkbox-inner-border-color: var(--color-text);
    }
  }

  ${ifProp(
    'hasError',
    `
    --checkbox-inner-border-color: var(--color-red500) !important;
  `
  )}
`;

/**
 * `Input` styled component.
 */

const Input = styled.input`
  cursor: pointer;
  grid-area: checkbox;
  height: var(--checkbox-size);
  opacity: 0;
  width: var(--checkbox-size);
  z-index: 1;
`;

/**
 * `LabelText` styled component.
 */

const LabelText = styled(Text).attrs({ variant: 'paragraph3' })`
  align-self: center;
  color: var(--color-text);
  grid-area: label;
  transition: var(--transition-default);
  transition-property: color, opacity;
  word-break: break-word;
`;

/**
 * `StyledErrorMessage` styled component.
 */

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: -4px;
  padding-top: 0;
`;

/**
 * Export `Checkbox` component.
 */

export const Checkbox = forwardRef(
  ({ className, disabled, error, id, label, name, ...props }: CheckboxProps, ref: any) => {
    return (
      <FormGroup className={className} data-cursor={'none'}>
        <Label disabled={disabled} hasError={!isEmpty(error)}>
          <Input disabled={disabled} id={id ?? name} name={name} ref={ref} type={'checkbox'} {...props} />

          <LabelText>{label}</LabelText>

          <CheckmarkWrapper>
            <Icon icon={checkboxCheckedSvg} size={'18px'} />
          </CheckmarkWrapper>
        </Label>

        {!!error && <StyledErrorMessage>{error}</StyledErrorMessage>}
      </FormGroup>
    );
  }
);

/**
 * `Checkbox` display name.
 */

Checkbox.displayName = 'Checkbox';
