/**
 * Module dependencies.
 */

import { AppProps } from 'src/types/app';
import { DefaultSeo, LogoJsonLd, OrganizationJsonLd } from 'next-seo';
import { ErrorBoundary } from 'src/components/core/error/error-boundary';
import { FormRequestProvider } from 'src/providers/form-request';
import { GlobalStyle } from 'src/styles/global';
import { GlobalStyle as GlobalStyleUntile } from '@untile/react-core/styles/global';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GridDebug } from 'src/components/core/debug';
import { Layout } from 'src/components/layout';
import { LocationName, locations } from 'src/core/constants/contacts';
import { NavigationProvider } from 'src/providers/navigation';
import { PrismicPreview } from '@prismicio/next';
import { ToastProvider } from 'src/providers/toast';
import { absoluteUrlResolve } from 'src/core/utils/url';
import { appWithTranslation } from 'next-i18next';
import { prismicRepoName } from 'src/prismic/client';
import { seoDefaultConfig } from 'src/core/constants/seo-default-config';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Script from 'next/script';
import pick from 'lodash/pick';

/**
 * Google tag manager id.
 */

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

/**
 * `App` component.
 */

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (googleTagManagerId && window?.dataLayer) {
        window?.dataLayer.push({
          event: 'pageview',
          page: url
        });
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta charSet={'UTF-8'} />

        <meta content={'IE=edge'} httpEquiv={'X-UA-Compatible'} />

        <meta content={'width=device-width, initial-scale=1'} name={'viewport'} />

        <meta content={'true'} name={'HandheldFriendly'} />

        <meta content={pageProps.locale} name={'language'} />

        <link href={'/favicon.ico'} rel={'shortcut icon'} type={'image/x-icon'} />

        <link href={'/favicons/apple-touch-icon.png'} rel={'apple-touch-icon'} sizes={'180x180'} />

        <link href={'/favicons/favicon-32x32.png'} rel={'icon'} sizes={'32x32'} type={'image/png'} />

        <link href={'/favicons/favicon-16x16.png'} rel={'icon'} sizes={'16x16'} type={'image/png'} />

        <link href={'/site.webmanifest'} rel={'manifest'} />

        <link color={'#05161b'} href={'/favicons/safari-pinned-tab.svg'} rel={'mask-icon'} />

        <meta content={'#ffffff'} name={'msapplication-TileColor'} />

        <meta content={'#ffffff'} name={'theme-color'} />
      </Head>

      {googleTagManagerId && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`}
            strategy={'beforeInteractive'}
          />

          <Script
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line id-match
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${googleTagManagerId}');
              `
            }}
            id={`gtag`}
            strategy={'afterInteractive'}
          />
        </>
      )}

      <GlobalStyleUntile />

      <GlobalStyle />

      <DefaultSeo {...seoDefaultConfig} />

      <PrismicPreview repositoryName={prismicRepoName} />

      <OrganizationJsonLd
        address={Object.keys(locations).map(location =>
          pick(locations[location as LocationName], [
            'addressCountry',
            'addressLocality',
            'postalCode',
            'streetAddress'
          ])
        )}
        legalName={seoDefaultConfig.openGraph?.siteName}
        logo={absoluteUrlResolve('/images/logo.jpg')}
        name={seoDefaultConfig.title as string}
        type={'Corporation'}
        url={seoDefaultConfig.openGraph?.url as string}
      />

      <LogoJsonLd logo={absoluteUrlResolve('/images/logo.jpg')} url={seoDefaultConfig.openGraph?.url as string} />

      <ErrorBoundary>
        {process.env.NEXT_PUBLIC_DEBUG_GRID === 'true' && <GridDebug />}

        <GoogleReCaptchaProvider
          language={pageProps?.locale}
          reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
          scriptProps={{ async: true }}
          useEnterprise
        >
          <FormRequestProvider>
            <ToastProvider>
              <NavigationProvider>
                <Layout hideFooter={pageProps?.hideFooter}>
                  <Component {...pageProps} />
                </Layout>
              </NavigationProvider>
            </ToastProvider>
          </FormRequestProvider>
        </GoogleReCaptchaProvider>
      </ErrorBoundary>
    </>
  );
}

/**
 * Export `App` component.
 */

export default appWithTranslation(App);
