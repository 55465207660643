/**
 * Module dependencies.
 */

import { prop } from 'styled-tools';
import { transparentize } from 'src/styles/utils/colors';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import times from 'lodash/times';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  inset: 0;
  left: var(--gutter);
  margin: 0 auto;
  max-width: var(--container-max-width);
  pointer-events: none;
  position: fixed;
  right: var(--gutter);
  z-index: 999;

  * {
    height: 100%;
  }
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div<{
  columns: number;
  gap: number;
}>`
  display: grid;
  grid-gap: ${prop('gap', 0)}px;
  grid-template-columns: repeat(${prop('columns')}, 1fr);
`;

/**
 * `Column` styled component.
 */

const Column = styled.div`
  background-color: ${transparentize('white', 0.1)};
`;

/**
 * Export `GridDebug` component.
 */

export const GridDebug = () => {
  const [visible, setVisible] = useState(false);
  const isMobile = useBreakpoint(0, 'sm');
  const columns = isMobile ? 4 : 12;
  const gap = isMobile ? 16 : 24;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.shiftKey && event.code === 'KeyG') {
        setVisible(!visible);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [visible]);

  return !visible ? null : (
    <Wrapper>
      <Grid columns={columns} gap={gap}>
        {times(columns, index => (
          <Column key={index} />
        ))}
      </Grid>
    </Wrapper>
  );
};
