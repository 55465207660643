/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Control, useForm } from 'react-hook-form';
import { Drawer, DrawerProps } from 'src/components/core/drawer';
import { InputField } from 'src/components/core/forms/input/field';
import { RecaptchaField } from 'src/components/core/forms/recaptcha';
import { TermsField } from 'src/components/core/forms/terms/field';
import { apiEndpoints } from 'src/core/constants/api-endpoints';
import { axiosInstance } from 'src/core/utils/requests';
import { regexes } from 'src/core/constants/regexes';
import { toast } from 'src/providers/toast';
import { useCallback } from 'react';
import { useEmailTemplateId } from 'src/hooks/use-email-template-id';
import { useTranslation } from 'next-i18next';

/**
 * Constants.
 */

const emailTemplateId = '33041912, 34448134, 35360208';

/**
 * `Props`  type.
 */

type Props = Omit<DrawerProps, 'children'>;

/**
 * `FormData` type.
 */

type FormData = {
  email: string;
  name: string;
};

/**
 * Export `CaseStudyFormRequest` component.
 */

export const CaseStudyFormRequest = ({ onRequestClose, ...props }: Props) => {
  const { t } = useTranslation();
  const { control, formState, handleSubmit, reset } = useForm<FormData>();
  const isSubmitting = formState.isSubmitting;
  const templateId = useEmailTemplateId(emailTemplateId);

  const handleOnSubmit = useCallback(
    ({ email, name }: FormData) => {
      return axiosInstance
        .post(apiEndpoints.sendEmail, {
          email,
          templateId,
          templateModel: {
            body: `Email: ${email}<br>Name: ${name}`,
            requestType: 'case study'
          }
        })
        .then(() => {
          reset();
          onRequestClose();
          toast.success(t('common:forms.messages.success'));
        })
        .catch(() => {
          toast.success(t('common:forms.messages.error'));
        });
    },
    [onRequestClose, reset, t, templateId]
  );

  return (
    <Drawer {...props} onRequestClose={onRequestClose}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <InputField
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.name')}
          name={'name'}
          placeholder={t('common:forms.placeholders.name')}
          rules={{
            required: t('common:forms.rules.required')
          }}
        />

        <InputField
          control={control}
          disabled={isSubmitting}
          label={t('common:forms.labels.email')}
          name={'email'}
          placeholder={t('common:forms.placeholders.email')}
          rules={{
            pattern: {
              message: t('common:forms.rules.email'),
              value: regexes.email
            },
            required: t('common:forms.rules.required')
          }}
          type={'email'}
        />

        <div>
          <TermsField control={control as any} disabled={isSubmitting} />
        </div>

        <RecaptchaField action={'caseStudy'} control={control as Control<any>} />

        <Button colorTheme={'primary'} disabled={isSubmitting} isLoading={isSubmitting} stretch type={'submit'}>
          {t('common:actions.sendMessage')}
        </Button>
      </form>
    </Drawer>
  );
};
