/**
 * Module dependencies.
 */

import { AnimationController } from 'src/components/core/animations/animation-controller';
import { Button } from 'src/components/core/buttons/button';
import { Container } from 'src/components/core/layout/container';
import { FadeInUp } from 'src/components/core/animations/fade-in-up';
import { LegalMenuItem, getLegalMenu } from 'src/core/constants/menu';
import { Locale } from 'i18n-routes';
import { Locations } from './locations';
import { Svg } from '@untile/react-core/components/svg';
import { Text } from 'src/components/core/text';
import { TextReveal } from 'src/components/core/animations/text-reveal';
import { Trans, useTranslation } from 'next-i18next';
import { getAnchor, routeResolve } from 'src/core/utils/routes';
import { media } from '@untile/react-core/styles/media';
import { transparentize } from 'src/styles/utils/colors';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useFormRequest } from 'src/providers/form-request';
import { useRouter } from 'next/router';
import instagramSvg from 'src/assets/svgs/instagram.svg';
import linkedinSvg from 'src/assets/svgs/linkedin.svg';
import logoSvg from 'src/assets/svgs/humanit-icon.svg';
import styled from 'styled-components';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.footer`
  padding: calc(var(--space-md) * 2) var(--gutter) 96px;
  position: relative;

  ${media.min.ms`
    padding: var(--space-lg) var(--gutter) 68px;
  `}
`;

/**
 * `Logo` styled component.
 */

const Logo = styled(Svg).attrs({
  icon: logoSvg,
  size: '68px'
})`
  color: var(--color-text);
  display: flex;
  margin: 0 auto var(--space-md);
  opacity: 0.08;

  ${media.min.ms`
    margin-bottom: 134px;
  `}
`;

/**
 * `Grid` styled component.
 */

const CardsGrid = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  margin-bottom: 72px;

  ${media.min.md`
    grid-gap: var(--space-xs);
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 96px;
  `}
`;

/**
 * `Card` styled component.
 */

const Card = styled.div`
  background-color: ${transparentize('lightBrown400', 0.1)};
  border-radius: var(--border-radius-base);
  display: flex;
  flex-direction: column;
  gap: var(--space-xs);
  justify-content: space-between;
  padding: 24px 56px 24px 24px;

  ${media.min.md`
    background-color: transparent;
    min-height: 424px;
    padding: 48px 56px;
    transition: background-color var(--transition-default);

    &:hover {
      background-color: ${transparentize('lightBrown400', 0.1) as any};
    }
  `}
`;

/**
 * `Foot` styled component.
 */

const Foot = styled(Text).attrs({
  as: 'div',
  variant: 'paragraph3'
})`
  align-items: center;
  display: grid;
  grid-template-areas: 'legalLinks copyright social';
  grid-template-columns: 10fr repeat(2, auto);

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    grid-area: legalLinks;

    > li:not(:last-child)::after {
      content: '•';
      margin-left: 8px;
    }
  }

  ${media.max.md`
    grid-row-gap: 8px;
    grid-template-areas: 'social' 'legalLinks' 'copyright';
    grid-template-columns: 1fr;
    justify-items: center;

    ul {
      justify-content: center;
    }
  `}
`;

/**
 * `Link` styled component.
 */

const Link = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  outline: none;
  transition: color var(--transition-default);
  white-space: nowrap;

  :focus,
  :focus-within,
  :hover {
    color: var(--color-cian700);
    text-decoration: underline;
  }
`;

/**
 * `Copyright` styled component.
 */

const Copyright = styled.span`
  grid-area: copyright;
  justify-self: center;

  ${media.min.md`
    justify-self: flex-end;
    margin-right: var(--space-xs);
  `}
`;

/**
 * `Social` styled component.
 */

const Social = styled.span`
  display: flex;
  gap: 16px;
  grid-area: social;

  ${Link} {
    color: var(--color-text);
    line-height: 0;
    text-decoration: none !important;
  }
`;

/**
 * `StyledSvg` styled component.
 */

const StyledSvg = styled(Svg)`
  background-color: var(--color-white);
  border-radius: 50%;
  color: var(--color-text);
`;

/**
 * Export `Footer` component.
 */

export const Footer = () => {
  const { t } = useTranslation();
  const { setOpenDrawer } = useFormRequest();
  const { locale } = useRouter();

  const isMobile = useBreakpoint(0, 'xs');
  const legalMenu = getLegalMenu(locale as Locale);

  return (
    <Wrapper>
      <Container>
        <Logo />

        <AnimationController>
          <CardsGrid>
            <Card>
              <Text as={'h4'} variant={'h3'}>
                <TextReveal text={t('common:footer.lead1')} />
              </Text>

              <FadeInUp>
                <Button
                  aria-label={t('common:actions.discoverOurPositions')}
                  href={`${routeResolve('careers', {
                    locale: locale as Locale
                  })}#${getAnchor('careers', 2)}`}
                  stretch={isMobile}
                >
                  {t('common:actions.discoverOurPositions')}
                </Button>
              </FadeInUp>
            </Card>

            <Card>
              <Text as={'h4'} variant={'h3'}>
                <TextReveal text={t('common:footer.lead2')} />
              </Text>

              <FadeInUp>
                <Button
                  aria-label={t('common:actions.connectWithUs')}
                  onClick={() => {
                    setOpenDrawer('contact');
                  }}
                  stretch={isMobile}
                >
                  {t('common:actions.connectWithUs')}
                </Button>
              </FadeInUp>
            </Card>
          </CardsGrid>
        </AnimationController>
      </Container>

      <Locations />

      <Container>
        <Foot>
          <ul>
            {legalMenu.map(({ name, url }: LegalMenuItem) => (
              <li key={name}>
                <Link aria-label={t(`common:legal.${name}`)} href={url}>
                  {t(`common:legal.${name}`)}
                </Link>
              </li>
            ))}
          </ul>

          <Copyright>
            <Trans
              components={[<Link aria-label={'Crafted by Untile'} href={'https://untile.pt'} key={'untile-link'} />]}
              i18nKey={'common:footer.copyright'}
              transSupportBasicHtmlNodes
              values={{ date: new Date().getFullYear() }}
            />
          </Copyright>

          <Social>
            <Link aria-label={'LinkedIn'} href={process.env.NEXT_PUBLIC_LINKEDIN_URL}>
              <StyledSvg icon={linkedinSvg} size={'32px'} />
            </Link>

            <Link aria-label={'Instagram'} href={process.env.NEXT_PUBLIC_INSTAGRAM_URL}>
              <StyledSvg icon={instagramSvg} size={'32px'} />
            </Link>
          </Social>
        </Foot>
      </Container>
    </Wrapper>
  );
};
