/**
 * Module dependencies.
 */

import { HTMLMotionProps, motion, useInView } from 'framer-motion';
import { ReactNode, useRef } from 'react';
import { useBreakpoint } from 'src/hooks/use-breakpoint';

/**
 * Variants.
 */

const variants = {
  animate: {
    opacity: 1,
    y: 0 // eslint-disable-line id-length
  },
  initial: {
    opacity: 0,
    y: 50 // eslint-disable-line id-length
  }
};

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  delay?: number;
  shouldAnimate?: boolean;
  triggerInView?: boolean;
} & HTMLMotionProps<'div'>;

/**
 * Export `FadeInUp` component.
 */

export const FadeInUp = (props: Props) => {
  const { children, delay, shouldAnimate = true, triggerInView, ...rest } = props;
  const ref = useRef(null);
  const isMobile = useBreakpoint(0, 'ms');
  const isInView = useInView(ref, { margin: '-80px 0px', once: true });
  const shouldStartAnimation = isInView && shouldAnimate;

  const transition = {
    duration: 0.8,
    ease: 'easeOut'
  };

  return isMobile || triggerInView ? (
    <motion.div
      {...rest}
      animate={shouldStartAnimation ? 'animate' : 'initial'}
      initial={'initial'}
      ref={ref}
      transition={{ delay: triggerInView ? delay : 0, ...transition }}
      variants={variants}
    >
      {children}
    </motion.div>
  ) : (
    <motion.div {...rest} ref={ref} transition={{ ...transition }} variants={variants}>
      {children}
    </motion.div>
  );
};
