/**
 * Module dependencies.
 */

import { AnimationController } from 'src/components/core/animations/animation-controller';
import { Carousel, Slide } from 'src/components/core/carousel';
import { Container } from 'src/components/core/layout/container';
import { FadeInUp } from 'src/components/core/animations/fade-in-up';
import { LocationCard } from './location-card';
import { Text } from 'src/components/core/text';
import { TextReveal } from 'src/components/core/animations/text-reveal';
import { locations, orderedLocations } from 'src/core/constants/contacts';
import { media } from '@untile/react-core/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useMouseDetect } from 'src/hooks/use-mouse-detect';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({
  variant: 'h1'
})`
  display: block;
  max-width: 712px;
`;

/**
 * `StyledCarousel` styled component.
 */

const StyledCarousel = styled(Carousel)`
  margin: 0 calc(var(--gutter) * -1);
  padding: 0 max(var(--gutter), calc(((100vw - var(--container-max-width)) / 2)));
  scroll-snap-type: x mandatory;

  .swiper-wrapper {
    margin-bottom: var(--space-md);
    padding-top: var(--space-md);

    ${media.min.ms`
      margin-bottom: var(--space-md);
      padding-top: var(--space-xl);
    `}
  }

  .swiper-slide {
    width: 100%;

    ${media.min.ms`
      width: 432px;
    `}
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;

/**
 * Export `Locations` component.
 */

export const Locations = () => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint(0, 'ms');
  const hasMouse = useMouseDetect();

  const [activeLocation, setActiveLocation] = useState<number>(0);

  const handleLocationClick = (index: number) => {
    setActiveLocation(index);
  };

  return (
    <AnimationController>
      <Container style={{ paddingTop: isMobile ? 'var(--space-md)' : 'var(--space-lg)' }}>
        <Title>
          <TextReveal text={t('common:footer.leadLocations')} />
        </Title>
      </Container>

      <FadeInUp>
        <StyledCarousel freeMode lerpSpeed={0.003} slidesPerView={'auto'} spaceBetween={16}>
          {orderedLocations.map((key, index) => (
            <Slide key={key}>
              <LocationCard
                isActive={!hasMouse ? true : index === activeLocation}
                onClick={() => handleLocationClick(index)}
                {...locations[key]}
              />
            </Slide>
          ))}
        </StyledCarousel>
      </FadeInUp>
    </AnimationController>
  );
};
