/**
 * Module dependencies.
 */

import { Control, RegisterOptions, useController } from 'react-hook-form';
import { InputFile, InputFileProps } from './index';
import { assignRefs } from 'src/core/utils/refs';
import { forwardRef } from 'react';

/**
 * Export `InputFieldProps` interface.
 */

export type InputFieldProps = Omit<InputFileProps, 'id'> & {
  control: Control<any>;
  name: string;
  rules?: RegisterOptions;
};

/**
 * Export `InputFileField` component.
 */

export const InputFileField = forwardRef((props: InputFieldProps, forwardedRef: any) => {
  const { control, name, rules, ...rest } = props;

  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error }
  } = useController({
    control,
    name,
    rules
  });

  return (
    <InputFile
      {...rest}
      error={error?.message}
      hasError={!!error}
      id={name}
      isRequired={!!rules?.required}
      onBlur={onBlur}
      onChange={event => {
        const input = event.target as HTMLInputElement;

        if (input.files) {
          onChange(input.files[0]);
        }
      }}
      ref={assignRefs(forwardedRef, ref)}
      value={value}
    />
  );
});

/**
 * `InputFileField` display name.
 */

InputFileField.displayName = 'InputFileField';
