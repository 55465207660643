/**
 * Module dependencies.
 */

import { ifProp } from 'styled-tools';
import { textStyles } from 'src/components/core/text';
import styled from 'styled-components';

/**
 * Export `FormGroup` styled component.
 */

export const FormGroup = styled.div`
  margin-bottom: var(--space-xs);
  opacity: 1;
  position: relative;
  transition: opacity var(--transition-default);

  ${ifProp(
    'disabled',
    `
    cursor: not-allowed;
    opacity: 0.7;
    pointer-events: none;
  `
  )}
`;

/**
 * Export `Label` styled component.
 */

export const Label = styled.label<{ isRequired?: boolean }>`
  color: var(--color-text);
  margin-bottom: 8px;

  &,
  sup {
    ${textStyles.paragraph2}
    font-weight: 700;
  }

  sup {
    color: var(--color-red500);
    left: 1px;
    top: -0.15rem;
  }
`;

/**
 * Export `FormControl` styled component.
 */

export const FormControl = styled.input`
  ${textStyles.paragraph2}

  appearance: none;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey600);
  border-radius: 3px;
  box-shadow: none;
  height: var(--space-ms);
  margin: 0;
  padding: 8px;
  transition: var(--transition-default);
  transition-property: background-color, border-color, color;
  width: 100%;

  ::placeholder {
    color: var(--color-grey600);
    transition: color var(--transition-default);
  }

  :focus,
  :focus-within,
  :hover {
    color: var(--color-grey700);
    outline: none;

    ::placeholder {
      color: var(--color-grey700);
    }
  }

  :hover {
    background-color: var(--color-grey100);
    border-color: var(--color-grey700);
  }

  :focus,
  :focus-within {
    background-color: var(--color-white);
    border-color: var(--color-text);
  }

  :is(textarea) {
    height: auto;
    min-height: var(--space-ms);
    resize: vertical;
  }

  ${ifProp(
    'hasError',
    `
    border-color: var(--color-red500);

    ::placeholder {
      color: var(--color-red500);
    }
  `
  )};

  ${ifProp(
    'value',
    `
    background-color: var(--color-white);
    border-color: var(--color-grey600);
    color: var(--color-grey800);
  `
  )};
`;

/**
 * Export `ErrorMessage` styled component.
 */

export const ErrorMessage = styled.div`
  ${textStyles.paragraph3}

  color: var(--color-red500);
  padding-top: 4px;
`;
