/**
 * Module dependencies.
 */

import { Router, useRouter } from 'next/router';
import { useBodyScroll } from '@untile/react-core/hooks/use-body-scroll';
import { useEffect, useRef, useState } from 'react';

/**
 * `Props` type.
 */

type Props = {
  hold?: number;
  onEnter?: () => void;
};

/**
 * Export `Animation` type.
 */

export type Animation = 'start' | 'end';

/**
 * Export `usePageTransition` hook.
 */

export function usePageTransition({ hold = 1000, onEnter }: Props) {
  const router = useRouter();
  const [animation, setAnimation] = useState<Animation>();
  const startTimestamp = useRef<number>();
  const endTimeout = useRef<NodeJS.Timeout>();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      onEnter?.();
      setAnimation('end');
    }
  }, [isMounted, onEnter]);

  useEffect(() => {
    const handleRouteChangeStart: Parameters<(typeof Router)['events']['on']>[1] = (_url, { shallow }) => {
      if (shallow) {
        return;
      }

      onEnter?.();
      startTimestamp.current = Date.now();

      setAnimation('start');
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events, router.pathname, onEnter]);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      clearTimeout(endTimeout.current);

      if (startTimestamp.current) {
        const timeElapsed = Date.now() - startTimestamp.current;

        if (timeElapsed < hold) {
          endTimeout.current = setTimeout(() => {
            setAnimation('end');
          }, hold - timeElapsed);

          return;
        }
      }

      setAnimation('end');
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      clearTimeout(endTimeout.current);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, [router.events, onEnter, hold]);

  useBodyScroll({ off: animation === 'start' });

  return animation;
}
