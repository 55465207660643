/**
 * Module dependencies.
 */

import { resolve } from 'url';

/**
 * Export `absoluteUrlResolve` util.
 */

export function absoluteUrlResolve(path: string): string {
  return resolve(process.env.NEXT_PUBLIC_BASE_URL as string, path);
}

/**
 * Export `assetUrlResolve` util.
 */

export function assetUrlResolve(path: string): string {
  const baseUrl = process.env.NEXT_PUBLIC_ASSETS_CDN_URL;
  const enable = process.env.NEXT_PUBLIC_ENABLE_CDN === 'true';

  return baseUrl && enable ? resolve(baseUrl, path) : path;
}

/**
 * Export `extractPath` util.
 */

export function extractPath(href: string) {
  const url = new URL(href);

  return `${url.pathname}${url.search}${url.hash}`;
}
