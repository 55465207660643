/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Svg } from '@untile/react-core/components/svg';
import { Text } from 'src/components/core/text';
import { Trans, useTranslation } from 'next-i18next';
import { Transition, TransitionStatus } from 'react-transition-group';
import { assetUrlResolve } from 'src/core/utils/url';
import { media } from '@untile/react-core/styles/media';
import { parseCookies, setCookie } from 'nookies';
import { switchProp } from 'styled-tools';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import cookiesSvg from 'src/assets/svgs/cookies.svg';
import styled from 'styled-components';

/**
 * `cookiesAcceptedName` constant.
 */

const cookiesAcceptedName = 'cookies-accepted';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ state: TransitionStatus }>`
  align-items: center;
  background-color: var(--color-white);
  border-radius: 8px;
  bottom: 20px;
  color: var(--color-text);
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: var(--space-sm) 1fr min-content;
  left: var(--gutter);
  margin: 0 auto;
  max-width: 375px;
  opacity: 0;
  padding: 7px 8px 7px 10px;
  position: fixed;
  right: var(--gutter);
  transition: var(--transition-default);
  transition-property: opacity, transform;
  z-index: var(--z-index-cookies);

  ${media.min.ms`
    bottom: var(--space-ms);
  `}

  button {
    height: 40px;
    min-width: initial;
    padding: 8px 16px;
  }

  ${switchProp('state', {
    entered: `
      opacity: 1;
      transform: translateY(0);
    `,
    entering: `
      opacity: 0;
      transform: translateY(50%);
    `,
    exited: `
      opacity: 0;
      transform: translateY(50%);
    `,
    exiting: `
      opacity: 0;
      transform: translateY(50%);
    `
  })}
`;

/**
 * Export `CookiesBar` component.
 */

export const CookiesBar = () => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClick = useCallback(() => {
    setIsOpen(false);
    setCookie(null, cookiesAcceptedName, 'true', {
      maxAge: 30 * 24 * 60 * 60,
      path: '/'
    });
  }, []);

  useEffect(() => {
    const accepted = parseCookies()?.[cookiesAcceptedName] === 'true';

    if (!accepted) {
      setIsOpen(true);

      return;
    }
  }, []);

  return (
    <Transition in={isOpen} mountOnEnter nodeRef={ref} timeout={350} unmountOnExit>
      {(state: TransitionStatus) => (
        <Wrapper ref={ref} state={state}>
          <Svg icon={cookiesSvg} size={'32px'} />

          <Text variant={'paragraph3'}>
            <Trans
              components={[
                <a
                  aria-label={t('common:legal.openCookiesPolicy')}
                  href={assetUrlResolve(`/files/${locale}/cookies-policy.pdf`)}
                  key={'terms-link'}
                  rel={'noopener noreferrer'}
                  style={{ textDecoration: 'underline' }}
                  target={'_blank'}
                />
              ]}
              i18nKey={'common:cookies.description'}
              transSupportBasicHtmlNodes
            />
          </Text>

          <Button onClick={handleClick}>{t('common:cookies.button')}</Button>
        </Wrapper>
      )}
    </Transition>
  );
};
