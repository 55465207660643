/**
 * Module dependencies.
 */

import { Animation } from 'src/hooks/use-page-transition';
import { getRotatedEllipseScale } from 'src/core/utils/geometry';
import { prop, switchProp } from 'styled-tools';
import { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

/**
 * `angle` constant.
 */

const angle = (-Math.PI * 2) / 15;

/**
 * `enter` keyframe animation.
 */

const enter = keyframes`
  from {
    transform: translate(75%, 75%);
  }

  to {
    transform: translate(0, 0);
  }
`;

/**
 * `exit` keyframe animation.
 */

const exit = keyframes`
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(-50%, -100%);
  }
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ angle?: number; scale?: number }>`
  height: ${100 * Math.SQRT2}vh;
  left: 50vw;
  pointer-events: none;
  position: fixed;
  top: 50vh;
  transform: translate(-50%, -50%) scale(${prop('scale', 2)}) rotate(${prop('angle', 0)}rad);
  width: ${100 * Math.SQRT2}vw;
  z-index: var(--z-index-page-transition);
`;

/**
 * `Shape` styled component.
 */

const Shape = styled.div<{ animation?: Animation }>`
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  background-color: white;
  border-radius: 50%;
  inset: 0;
  position: fixed;

  ${switchProp('animation', {
    end: css`
      animation-name: ${exit};
      pointer-events: none;
      transform: translate(75%, 75%);
    `,
    start: css`
      animation-name: ${enter};
      pointer-events: all;
    `
  })}
`;

/**
 * Export `Ellipse` component.
 */

export const Ellipse = ({ animation }: { animation?: Animation }) => {
  const [scale, setScale] = useState<number>();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    setScale(
      getRotatedEllipseScale({
        angle,
        vh: window.innerHeight,
        vw: window.innerWidth
      })
    );
  }, []);

  return (
    <Wrapper angle={angle} scale={scale}>
      <Shape animation={animation} />
    </Wrapper>
  );
};
