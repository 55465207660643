/**
 * Module dependencies.
 */

import { keyframes as keyframesCss } from 'styled-components';

/**
 * `fadeIn` keyframes.
 */

const fadeIn = keyframesCss`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

/**
 * `fadeInDelayed` keyframes.
 */

const fadeInDelayed = keyframesCss`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

/**
 * `fadeOut` keyframes.
 */

const fadeOut = keyframesCss`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

/**
 * `spin` keyframe.
 */

const spin = keyframesCss`
  to {
    transform: rotate(360deg);
  }
`;

/**
 * `keyframes`.
 */

const keyframes = {
  fadeIn,
  fadeInDelayed,
  fadeOut,
  spin
};

/**
 * Export `keyframes`.
 */

export default keyframes;
