/**
 * Module dependencies.
 */

import 'react-toastify/dist/ReactToastify.css';
import { ReactNode } from 'react';
import { Slide, ToastContainer, ToastOptions, toast as reactToast } from 'react-toastify';
import { Svg } from '@untile/react-core/components/svg';
import { textStyles } from 'src/components/core/text';
import closeSvg from 'src/assets/svgs/close-small.svg';
import errorSvg from 'src/assets/svgs/error.svg';
import styled, { createGlobalStyle } from 'styled-components';
import successSvg from 'src/assets/svgs/success.svg';

/**
 * `Props` type.
 */

type Props = {
  children?: ReactNode;
};

/**
 * `CloseButtonProps` type.
 */

type CloseButtonProps = {
  closeToast: (e: React.MouseEvent<HTMLElement>) => void;
};

/**
 * Toast global styles.
 */

const ToastGlobalStyles = createGlobalStyle`
  :root {
    --toastify-button-color-error: var(--color-red600);
    --toastify-button-color-success: var(--color-green600);
    --toastify-color-error: var(--color-red500);
    --toastify-color-success: var(--color-green500);
    --toastify-font-family: var(--font-family-poly-sans);
    --toastify-text-color-light: var(--color-white);
    --toastify-toast-min-height: 48px;
    --toastify-toast-width: 475px;
    --toastify-z-index: var(--z-index-toast);
  }

  .Toastify__toast {
    border-radius: 8px;
    box-shadow: none;
    display: grid;
    grid-template-columns: 1fr 40px;
    overflow: hidden;
    padding: 0;

    &-body {
      align-items: flex-start;
      padding: 8px;
      white-space: pre-line;
      word-break: break-word;

      > div:last-child {
        ${textStyles.paragraph2}
      }
    }

    &-icon {
      position: relative;
      top: 3px;
      width: 24px;
    }

    ${['error', 'success'].map(
      key => `
      &--${key} {
        background-color: var(--toastify-color-${key});

        button:hover {
          background-color: var(--toastify-button-color-${key});
        }
      }
    `
    )}
  }
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled.button`
  align-self: flex-start;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  color: var(--color-white);
  cursor: pointer;
  height: 40px;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition: background-color var(--transition-default);
  width: 40px;
`;

/**
 * Alert Icons.
 */

const alertIcons = {
  default: null,
  error: errorSvg,
  info: null,
  success: successSvg,
  warning: null
} as const;

/**
 * Default options.
 */

const defaultOptions = {
  icon: ({ type }: { type: keyof typeof alertIcons }) => {
    return <Svg icon={alertIcons?.[type]} size={'24px'} />;
  }
};

/**
 * Export `toast` util.
 */

export const toast = {
  error: (message: ReactNode, options?: ToastOptions) => {
    reactToast.error(message, { ...defaultOptions, ...options });
  },
  success: (message: ReactNode, options?: ToastOptions) => {
    reactToast.success(message, { ...defaultOptions, ...options });
  }
} as const;

/**
 * `CloseButton` component.
 */

function CloseButton({ closeToast }: CloseButtonProps) {
  return (
    <StyledButton onClick={closeToast}>
      <Svg icon={closeSvg} size={'24px'} />
    </StyledButton>
  );
}

/**
 * Export `ToastProvider` component.
 */

export function ToastProvider({ children }: Props) {
  return (
    <>
      {children}

      <ToastGlobalStyles />

      <ToastContainer
        autoClose={5000}
        closeButton={CloseButton}
        closeOnClick
        hideProgressBar
        limit={4}
        newestOnTop
        position={reactToast.POSITION.TOP_RIGHT}
        transition={Slide}
      />
    </>
  );
}
