/**
 * Module dependencies.
 */

import { DefaultSeoProps } from 'next-seo';
import { absoluteUrlResolve } from 'src/core/utils/url';
import packageJson from 'package.json';

/**
 * Export `seoDefaultConfig`.
 */

export const seoDefaultConfig: DefaultSeoProps = {
  additionalMetaTags: [
    {
      content: packageJson.version,
      name: 'version'
    },
    {
      content: 'Untile',
      name: 'author'
    },
    {
      content: 'Worldwide',
      name: 'coverage'
    },
    {
      content: 'Global',
      name: 'destribution'
    },
    {
      content: 'General',
      name: 'rating'
    },
    {
      content: process.env.NEXT_PUBLIC_EMAIL,
      name: 'reply-to'
    },
    {
      content: '375',
      name: 'MobileOptimized'
    }
  ],
  defaultTitle: 'HumanIT - Digital Consulting',
  openGraph: {
    images: [
      {
        alt: 'HumanIT - Digital Consulting',
        height: 600,
        url: absoluteUrlResolve('/images/share-image.jpg'),
        width: 1200
      }
    ],
    siteName: 'HumanIT',
    url: process.env.NEXT_PUBLIC_BASE_URL
  }
};
