/* eslint-disable new-cap */

/**
 * Module dependencies.
 */

import { colors } from './colors';
import { createCssVariables } from './utils/mixins';
import { css } from 'styled-components';
import { media } from '@untile/react-core/styles/media';
import { polySansFont } from './fonts';
import { transparentize } from './utils/colors';

/**
 * Export `rootVariables`.
 */

export const rootVariables = css`
  :root {
    /* Border */
    --border-radius-base: var(--space-xs);

    /* Colors */
    ${createCssVariables(colors, 'color')}
    --color-primary: var(--color-cian600);
    --color-secondary: var(--color-lightBrown300);
    --color-text: var(--color-darkBlue700);

    /* Fonts */
    --font-family-poly-sans: ${polySansFont.style.fontFamily};

    /* Gradients */
    --gradient-default: linear-gradient(180deg, rgba(244, 244, 239, 0.17) 0%, var(--color-lightBrown300) 100%);
    --gradient-secondary: linear-gradient(180deg, rgba(5, 22, 27, 0.1) 0%, rgba(5, 22, 27, 0.7) 100%);

    /* Layout */
    --container-max-width: 1280px;
    --gutter: 20px;

    ${media.min.sm`--gutter: 24px;`}

    @media (min-width: 1441px) {
      --container-max-width: 1440px;
    }

    /* Lightbox */
    --yarl__button_filter: none;
    --yarl__color_backdrop: ${transparentize('darkBlue700', 0.98)};

    /* Navbar */
    --navbar-height: 64px;

    ${media.min.sm`--navbar-height: 96px;`}

    /* Scrollbar */
    --scrollbar-background: var(--color-secondary);
    --scrollbar-border-radius: 8px;
    --scrollbar-thumb-background: var(--color-lightBrown400);
    --scrollbar-width: 16px;
    --scrollbar-thumb-width: 8px;

    /* Spaces */
    --space-xxs: 16px;
    --space-xs: 24px;
    --space-sm: 32px;
    --space-ms: 48px;
    --space-md: 64px;
    --space-lg: 120px;
    --space-xl: 168px;

    /* Text selection */
    --text-selection-background: var(--color-text);
    --text-selection-color: var(--color-white);

    /* Transitions */
    --transition-animation: cubic-bezier(0.4, 0, 0.2, 1);
    --transition-default: 350ms var(--transition-animation);
    --transition-fast: 150ms var(--transition-animation);
    --transition-slow: 500ms ease-in-out;

    /* z-indexes */
    --z-index-cookies: 95;
    --z-index-cursor: 999;
    --z-index-drawer: 98;
    --z-index-mobile-menu: 96;
    --z-index-navbar: 97;
    --z-index-navigation: 96;
    --z-index-page-transition: 100;
    --z-index-page-transition-tag: 101;
    --z-index-toast: 99;
  }
`;
