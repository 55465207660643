/**
 * Module dependencies.
 */

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FreeMode, Navigation, Pagination } from 'swiper/modules';
import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react';
import { isMobile, isTablet } from 'react-device-detect';

/**
 * `Props` type.
 */

type Props = Omit<SwiperProps, 'modules'> & {
  children: ReactNode;
  className?: string;
  increaseLerpSpeedOnEdges?: boolean;
  lerpSpeed?: number;
  mouseScroll?: boolean;
};

/**
 * Export `Slide` component.
 */

export const Slide = SwiperSlide;

/**
 * Export `Carousel` component.
 */

export const Carousel = (props: Props) => {
  const { children, increaseLerpSpeedOnEdges, lerpSpeed = 0.005, mouseScroll = true, ...rest } = props;
  const animationFrameId = useRef<number | null>(null);
  const currentTranslateXRef = useRef<number>(0);
  const latestMouseMoveEvent = useRef<MouseEvent | null>(null);
  const swiperContainerRef = useRef<HTMLDivElement | null>(null);
  const swiperRef = useRef<SwiperClass | null>(null);

  const handleMouseMove = (event: MouseEvent) => {
    latestMouseMoveEvent.current = event;
  };

  const animate = useCallback(() => {
    if (latestMouseMoveEvent.current && swiperRef.current && swiperContainerRef.current) {
      const swiperElement = swiperRef.current;
      const maxTranslateX = (swiperElement as any).virtualSize - swiperElement.width;

      const mouseX = latestMouseMoveEvent.current.clientX;
      const containerWidth = swiperContainerRef.current.clientWidth;

      const halfContainerWidth = containerWidth / 2;
      const mouseOffset = halfContainerWidth - mouseX;
      const mouseOffsetPercentage = (mouseOffset / halfContainerWidth) * 100;

      let adjustedLerpSpeed = lerpSpeed;

      if (Math.abs(mouseOffsetPercentage) < 4) {
        animationFrameId.current = requestAnimationFrame(animate);

        return;
      }

      if (Math.abs(mouseOffsetPercentage) >= 4) {
        adjustedLerpSpeed *= 0.6;
      }

      if (Math.abs(mouseOffsetPercentage) >= 8) {
        adjustedLerpSpeed *= 0.8;
      }

      if (Math.abs(mouseOffsetPercentage) >= 10) {
        adjustedLerpSpeed = lerpSpeed;
      }

      if (increaseLerpSpeedOnEdges && Math.abs(mouseOffsetPercentage) >= 82) {
        adjustedLerpSpeed *= 1.3;
      }

      if (increaseLerpSpeedOnEdges && Math.abs(mouseOffsetPercentage) >= 90) {
        adjustedLerpSpeed *= 1.6;
      }

      const translateX = parseFloat(
        Math.max(
          0,
          Math.min(maxTranslateX, currentTranslateXRef.current + mouseOffset * adjustedLerpSpeed * -1)
        ).toFixed(2)
      );

      swiperElement.setTranslate(-translateX);
      currentTranslateXRef.current = translateX;
    }

    animationFrameId.current = requestAnimationFrame(animate);
  }, [increaseLerpSpeedOnEdges, lerpSpeed]);

  useEffect(() => {
    if (isMobile || isTablet || !mouseScroll) {
      return;
    }

    const swiperContainer = swiperContainerRef.current;

    swiperContainer?.addEventListener('mousemove', handleMouseMove);
    animationFrameId.current = requestAnimationFrame(animate);

    return () => {
      swiperContainer?.removeEventListener('mousemove', handleMouseMove);
    };
  }, [animate, mouseScroll]);

  return (
    <div ref={swiperContainerRef}>
      <Swiper {...rest} modules={[FreeMode, Navigation, Pagination]} onSwiper={swiper => (swiperRef.current = swiper)}>
        {children}
      </Swiper>
    </div>
  );
};
