/**
 * Export `regexes`.
 */

export const regexes = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  htmlTags: /<[a-z][\s\S]*>/i,
  interpolateUrl: /:([\s\S]+?)\b/g,
  phone: /^\d{8,15}$/i
};
