/**
 * Module dependencies.
 */

import { Loading } from 'src/components/core/loading';
import { Location as LocationProps } from 'src/core/constants/contacts';
import { Svg } from '@untile/react-core/components/svg';
import { Text, textStyles } from 'src/components/core/text';
import { assetUrlResolve } from 'src/core/utils/url';
import { ifProp } from 'styled-tools';
import { media } from '@untile/react-core/styles/media';
import { transparentize } from 'src/styles/utils/colors';
import { useTranslation } from 'next-i18next';
import HoverVideoPlayer from 'react-hover-video-player';
import gotoSvg from 'src/assets/svgs/goto.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = LocationProps & {
  isActive: boolean;
  onClick: () => void;
};

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  min-width: 300px;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
    pointer-events: none;
  }
`;

/**
 * `StyledHoverVideoPlayer` styled component.
 */

const StyledHoverVideoPlayer = styled(HoverVideoPlayer)`
  display: 'block';
  height: '100%';
  opacity: 0;
  transform: scale(0.6);
  transition: all var(--transition-default);
  width: '100%';

  * {
    border-radius: var(--space-xs);
  }
`;

/**
 * `Name` styled component.
 */

const Name = styled(Text).attrs({ variant: 'h4' })`
  align-items: center;
  bottom: 0;
  color: ${transparentize('darkBlue700', 0.5)};
  display: flex;
  justify-content: center;
  left: 0;
  line-height: 1.1;
  position: absolute;
  right: 0;
  top: 0;
  transition: color var(--transition-default);
  z-index: 1;
`;

/**
 * `Details` styled component.
 */

const Details = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  opacity: 0;
  padding: var(--space-xs);
  transform: translateY(30px);
  transition: all var(--transition-default);

  ${media.max.ms`
    padding: 0;
  `}
`;

/**
 * `Address` styled component.
 */

const Address = styled(Text).attrs({ as: 'div', variant: 'paragraph2' })`
  color: var(--color-darkBlue700);
  white-space: break-spaces;
`;

/**
 * `MapLink` styled component.
 */

const MapLink = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  ${textStyles.paragraph3}

  align-items: center;
  color: var(--color-darkBlue700);
  display: flex;
  font-weight: 700;
  gap: 8px;
  grid-area: mapLink;
  outline: none;
  pointer-events: auto;
  transition: color var(--transition-default);
  width: fit-content;

  :focus,
  :focus-within,
  :hover {
    color: var(--color-cian700);
  }
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div.withConfig({
  shouldForwardProp: prop => !['isActive'].includes(prop)
})<{ isActive?: boolean }>`
  width: 100%;

  :hover {
    ${Name} {
      color: var(--color-darkBlue700);
    }

    ${StyledHoverVideoPlayer} {
      opacity: 0.5;
      transform: scale(0.8);
    }
  }

  ${ifProp(
    'isActive',
    `
      ${Name} {
        color: var(--color-white) !important;
      }

      ${StyledHoverVideoPlayer} {
        opacity: 1 !important;
        transform: scale(1) !important;
      }

      ${Details} {
        opacity: 1;
        transform: translateY(0);
      }
    `
  )}
`;

/**
 * Export `LocationCard` component.
 */

export const LocationCard = (props: Props) => {
  const {
    addressCountry,
    addressLocality,
    isActive,
    lat,
    lng,
    locationVideoUrl,
    name,
    onClick,
    postalCode,
    streetAddress
  } = props;

  const { t } = useTranslation();

  return (
    <Wrapper isActive={isActive} onClick={onClick}>
      <ImageWrapper>
        <StyledHoverVideoPlayer
          disableDefaultEventHandling
          focused
          loadingOverlay={<Loading />}
          loop
          videoSrc={assetUrlResolve(locationVideoUrl)}
          videoStyle={{ height: '100%' }}
        />

        <Name>{t(name)}</Name>
      </ImageWrapper>

      <Details>
        <Address>
          <div>{streetAddress}</div>

          <div>{`${postalCode} ${t(addressLocality)}, ${t(addressCountry)}`}</div>
        </Address>

        <MapLink aria-label={t('common:actions.findYourWay')} href={`https://www.google.com/maps/?q=${lat},${lng}`}>
          {t('common:actions.findYourWay')}

          <Svg icon={gotoSvg} size={'24px'} />
        </MapLink>
      </Details>
    </Wrapper>
  );
};
