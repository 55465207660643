/**
 * Module dependencies.
 */

import { KeyTextField } from '@prismicio/client';
import { RawHtml } from '@untile/react-core/components/raw-html';
import { motion, useInView } from 'framer-motion';
import { regexes } from 'src/core/constants/regexes';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useMemo, useRef } from 'react';
import styled from 'styled-components';

/**
 * Variants.
 */

const wrapperVariants = {
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  },
  initial: {
    opacity: 0
  }
};

const wordVariants = {
  animate: {
    opacity: 1,
    rotate: 0,
    transition: {
      duration: 0.5
    },
    y: 0 // eslint-disable-line id-length
  },
  initial: {
    opacity: 0,
    rotate: 5,
    transformOrigin: 'bottom left',
    y: '100%' // eslint-disable-line id-length
  }
};

/**
 * `WordProps` type.
 */

type WordProps = {
  onAnimationComplete?: () => void;
  word: string;
};

/**
 * `Props` type.
 */

type Props = {
  onAnimationComplete?: () => void;
  shouldAnimate?: boolean;
  text: KeyTextField | undefined;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(motion.span)`
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

/**
 * `Word` component.
 */

const Word = ({ onAnimationComplete, word }: WordProps) => {
  const wordToRender = useMemo(() => {
    return regexes.htmlTags.test(word) ? <RawHtml>{word}</RawHtml> : word;
  }, [word]);

  return (
    <Wrapper>
      <motion.span
        onAnimationComplete={onAnimationComplete}
        style={{ display: 'inline-block' }}
        variants={wordVariants}
      >
        {wordToRender}
      </motion.span>
    </Wrapper>
  );
};

/**
 * Export `TextReveal` component.
 */

export const TextReveal = ({ onAnimationComplete, shouldAnimate = true, text }: Props) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { margin: '-80px 0px', once: true });
  const shouldStartAnimation = isInView && shouldAnimate;
  const isMobile = useBreakpoint(0, 'ms');

  if (typeof text === 'string') {
    const textSplited = text.split(' ');
    const textSplitedLength = textSplited.length - 1;

    return isMobile ? (
      <Wrapper
        animate={shouldStartAnimation ? 'animate' : 'initial'}
        initial={'initial'}
        ref={ref}
        variants={wrapperVariants}
      >
        {textSplited.map((word, index) => (
          <Word
            key={index}
            onAnimationComplete={onAnimationComplete}
            word={word + (index !== textSplitedLength ? '\u00A0' : '')}
          />
        ))}
      </Wrapper>
    ) : (
      <Wrapper ref={ref} variants={wrapperVariants}>
        {textSplited.map((word, index) => (
          <Word
            key={index}
            onAnimationComplete={index === textSplitedLength ? onAnimationComplete : undefined}
            word={word + (index !== textSplitedLength ? '\u00A0' : '')}
          />
        ))}
      </Wrapper>
    );
  }
};
