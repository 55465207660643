/**
 * Module dependencies.
 */

import { ErrorMessage, FormControl, FormGroup, Label } from 'src/components/core/forms/styles';
import { Ref, forwardRef } from 'react';
import { Svg } from '@untile/react-core/components/svg';
import { ifNotProp } from 'styled-tools';
import caretSvg from 'src/assets/svgs/caret.svg';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Export `SelectProps` interface.
 */

export type SelectProps = React.HTMLProps<HTMLSelectElement> & {
  className?: string;
  disabled: boolean;
  error?: string;
  hasError?: boolean;
  id: string;
  isRequired?: boolean;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: Array<{ label: string; value: string }>;
  ref?: Ref<HTMLSelectElement>;
};

/**
 * `StyledSelect` styled component.
 */

const StyledSelect = styled(FormControl).attrs({
  as: 'select'
})<Omit<SelectProps, 'error' | 'isRequired' | 'label' | 'options'>>`
  ${ifNotProp(
    'hasValue',
    `
    color: var(--color-grey600);
  `
  )}
` as any;

/**
 * `Caret` styled component.
 */

const Caret = styled(Svg).attrs({
  icon: caretSvg,
  size: '24px'
})`
  color: var(--color-secondary);
  pointer-events: none;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

/**
 * Export `Select` component.
 */

export const Select = forwardRef((props: SelectProps, ref: any) => {
  const { className, error, hasError, id, isRequired, label, options, placeholder, value, ...rest } = props;

  return (
    <FormGroup className={className} data-cursor={'none'}>
      <Label htmlFor={id}>
        {label}

        {isRequired && <sup>{'*'}</sup>}
      </Label>

      <div style={{ position: 'relative' }}>
        <StyledSelect {...rest} hasError={hasError} hasValue={!isEmpty(value)} ref={ref} value={value}>
          {placeholder && (
            <option disabled hidden selected value={''}>
              {placeholder}
            </option>
          )}

          {options.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </StyledSelect>

        <Caret />
      </div>

      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </FormGroup>
  );
});

/**
 * `Select` display name.
 */

Select.displayName = 'Select';
