/**
 * Module dependencies.
 */

import { extractPath } from 'src/core/utils/url';
import { i18n } from 'src/core/constants/i18n';
import { routeResolve } from 'src/core/utils/routes';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

/**
 * Export `HrefLang` type.
 */

export type HrefLang = {
  locale: string;
  route: string;
};

/**
 * `fetchHreflangs`.
 */

function fetchHreflangs(): HrefLang[] {
  const hreflangElements = [...document.querySelectorAll('link[hreflang]')];

  const hreflangsFromDOM = hreflangElements.map(element => ({
    locale: element.getAttribute('hreflang') || '',
    route: extractPath(element.getAttribute('href') || '')
  }));

  const missingLocales = i18n.locales
    .filter(locale => !hreflangsFromDOM.some(hl => hl.locale === locale))
    .map(locale => ({
      locale,
      route: routeResolve('home', { locale })
    }));

  return hreflangsFromDOM.concat(missingLocales);
}

/**
 * Export `useHreflangs` hook.
 */

export function useHreflangs() {
  const [hreflangs, setHreflangs] = useState<HrefLang[]>([]);
  const router = useRouter();

  useEffect(() => {
    setHreflangs(fetchHreflangs());
  }, [router]);

  return hreflangs;
}
